import React from 'react';
import * as ninePlayer from '../../assets/css/nineplayer';
import * as Animation from '../../config/CardShuffleArrangs';

function ChipsInfoPotAndPlayer(props) {
    const {
        hand_info,
        player_info
    } = props;

    let dealerid = (hand_info && player_info && player_info.filter(p => p.name === hand_info.dealer).length > 0) ? player_info.filter(p => p.name === hand_info.dealer)[0].seat_no : null
    let sbid = (hand_info && player_info && player_info.filter(p => p.name === hand_info.small_blind).length > 0) ? player_info.filter(p => p.name === hand_info.small_blind)[0].seat_no : null
    let bbid = (hand_info && player_info && player_info.filter(p => p.name === hand_info.big_blind).length > 0) ? player_info.filter(p => p.name === hand_info.big_blind)[0].seat_no : null

    let currentCSS = ninePlayer;

    return <>
        <div className="center_pot">
            <span style={{ fontWeight: 'bolder', fontSize: '1.8vh' }}>{hand_info.hand_pot_chips}</span>
        </div>
        <div className="all_pots">
            {hand_info && hand_info.pot && hand_info.pot.map((p, i) => <div key={`vv${i}`} className="all_pot_stack">
                <span style={{ color: 'white', fontWeight: 'bolder', fontSize: '1.8vh' }}>{i + 1}: {p.total} </span>
            </div>)}
        </div>
        <div className="player_chips_and_blind">
            {player_info && player_info.map((p, i) => {
                return <div id={p.seat_no} key={`bbx${i}`} style={{ ...currentCSS[`pot${p.seat_no}`], zIndex: '999999999999999999999999999999999' }}>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        {dealerid === p.seat_no && <div style={{ backgroundColor: 'white', height: '2.3vh', width: '2.3vh', borderRadius: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '3px' }}>
                            <span style={{ color: 'black', fontWeight: 'bold', textAlign: 'center', fontSize: '1.4vh' }}>D</span>
                        </div>}
                        {sbid === p.seat_no && <div style={{ backgroundColor: 'blue', height: '2.3vh', width: '2.3vh', borderRadius: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '3px' }}>
                            <span style={{ color: '#fff', fontWeight: 'bold', textAlign: 'center', fontSize: '1.4vh' }}>SB</span>
                        </div>}
                        {bbid === p.seat_no && <div style={{ backgroundColor: 'yellow', height: '2.3vh', width: '2.3vh', borderRadius: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '3px' }}>
                            <span style={{ color: 'black', fontWeight: 'bold', textAlign: 'center', fontSize: '1.4vh' }}>BB</span>
                        </div>}
                    </div>
                    {(hand_info && hand_info.players[p.name] && hand_info.players[p.name].round_chips_arr) && hand_info.players[p.name].round_chips_arr.filter(d => d !== "").length > 0 &&
                        <div id="pl_chips_animate" className={p.seat_no} style={{ marginTop: '3px' }}>
                            <img style={{ color: 'white', top: '2.2vh', left: '0vw', textAlign: 'center', position: 'absolute', zIndex: 2, height: '4vh' }} src={require(`../../assets/red-poker-chipstack.png`)} />
                            <div style={{ background: '#000', left: '2vh', top: '3vh', position: 'absolute', zIndex: 1, border: '1px solid #353535', borderRadius: '8px', height: '1.3vh', padding: '0.55vh 5px 0.55vh 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '1.5vw' }}>
                                <span style={{ color: 'white', fontWeight: 'bolder', fontSize: '1.5vh' }}> {hand_info.players[p.name].round_chips_betted} </span>
                            </div>
                        </div>}
                </div>
            })}
        </div>
    </>
}

const ChipsInfoPotAndPlayerMemo = React.memo(ChipsInfoPotAndPlayer);
export default ChipsInfoPotAndPlayerMemo;