import React, { useEffect } from 'react';
import './App.css';
import './App.scss';
import db from './config/firebase';
import { Provider } from 'react-redux';
import store from './redux/store';
import RouterList from './config/router';
import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css";

function App() {

  const getEmail = () => {
    let userinfo = localStorage.getItem('userEmail');
    if(userinfo) {
      return userinfo
    }
    return null;
  }

  return (
    <>
      <Provider store={store}>
        <RouterList />
      </Provider>
      <Feedback emailDefaultValue={getEmail()} projectId="60d09911647378000476a62c" />
    </>
  );
}

export default App;
