import React from 'react';
import Avatar from 'react-avatar';
import avatar1 from '../assets/avatar1.jpg';

function ClubList(props) {

    const openClubDetails = (name) => {
        props.history.replace('/club/' + name);

    }

    return (
        <div onClick={() => openClubDetails(props.data.club_id)} style={{ cursor: "pointer" }} className="game">
            <div className="title">{props.data.club_name}</div>
            <div className="title">{props.data.club_id}</div>
            <div className="frame">
                <div className="buyin">
                </div>
                <div className="players">
                </div>
                <div className="players">
                    {/* <label>BLINDS</label>
                    <span>25 / 50</span> */}
                </div>
                <div className="action">
                    <a href="javascript:void(0)" className="btn btn-info btn-xs btn-block btnPlayNow">View Details</a>
                </div>
            </div>
        </div>
    )
}

export default ClubList;
