import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { connect } from "react-redux";
import * as loginAction from '../redux/actions/login-action';
import { bindActionCreators } from 'redux';
import store from '../redux/store';
import Club from './Club';

import { useCookies } from 'react-cookie';

import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap';

import '../assets/css/login.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';

function Login(props) {
    const [showLogin, setShowLogin] = useState(true);
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [screen_name, setUserID] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rpassword, setRPassword] = useState('');

    const [cookies, setCookie, removeCookie] = useCookies(['se']);


    useEffect(() => {
        let userinfo = localStorage.getItem('user')
        if (userinfo) {
            props.history.push('/club')
        }
    }, [])

    const showBlock = (value) => {
        setError('');
        setMessage('');
        setShowLogin(value);
        setEmail('');
        setPassword('');
        setUserID('');
    }

    const verifyPassword = () => {
        if(password === rpassword) {
            userSignUp();
        } else {
            setError("Password mis match")
        }
    }

    const userSignUp = () => {
        setError('');
        setMessage('');
        firebase.auth().createUserWithEmailAndPassword(email, password).then(res => {
            let data = {
                firebase_id: res.user.uid,
                screen_name: screen_name,
                email: email,
                chips: 0,
                first_name: name,
                last_name: "",
                photo_url: "",
                gender: "M"
            }
            props.loginAction.register(data, (res) => {
                if (res !== 'error' && res.status === 200) {
                    showBlock(true);
                    setMessage("Registerd Successfully");
                }
                if (res !== 'error' && res.status !== 200) {
                    setError(res.data)
                }
            })
        }).catch(error => {
            setError(error.message)
        });

    }

    const onPressEnter = (e) => {
        if (e.key === 'Enter' && password && email) {
            userLogin();
        }
    }

    const userLogin = () => {
        setError('');
        setMessage('');
        firebase.auth().signInWithEmailAndPassword(email, password).then(res => {
            props.loginAction.getToken({ email }, (response) => {
                setCookie('se', response.data, { path: '/' });
                localStorage.setItem('idToken', res.user.xa);
                localStorage.setItem('rfToken', res.user.refreshToken);
                localStorage.setItem('user', res.user.uid);
                localStorage.setItem('userEmail', email);
                setMessage(res.data);
                props.history.replace('/club');
                window.location.reload();
            })

        }).catch(error => {
            setError(error.message)
        });
    }

    const forgotPassword = () => {
        if (email) {
            firebase.auth().sendPasswordResetEmail(email).then((res) => {
                setMessage('Please check your mail inbox');
            }).catch((err) => console.log("-==-err--==- ", err))
        }
    }

    return (
        <>
            {/* <Navbar collapseOnSelect expand="lg" className="nav-top">
                <Navbar.Brand href="#home">
                    <header>
                        <h4>Z</h4>
                        <h3>Poker</h3>
                    </header>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="#">Home</Nav.Link>
                        <Nav.Link href="#">Z-Poker</Nav.Link>
                        <Nav.Link href="#">How To Play</Nav.Link>
                        <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Tournaments</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Rewards</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Feature</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Promotion</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <div id="menu_item">
                            <div id="menu">
                                <a>{`Admin`}</a>
                            </div>
                            <div id="menu">
                                <a onClick={() => props.history.push('/login')} class="btn btn-sm lbtn btn-outline-color" >
                                    <span class="fa fa-gamepad"></span>&nbsp;&nbsp;Play Now
                        </a>
                            </div>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar> */}
            <header id="home_header">
                <header onClick={() => props.history.push('/')}>
                    <h4>Z</h4>
                    <h3>Poker</h3>
                </header>
                <div id="menu_item">
                    <div id="menu">
                        <a>
                            Admin
                        </a>
                    </div>
                    <div id="menu">
                        <a class="btn btn-sm lbtn btn-outline-color" >
                            <span class="fa fa-gamepad"></span>&nbsp;&nbsp;Play Now
                        </a>
                    </div>
                </div>
            </header>
            <main id="login">
                <h3>{message}</h3>
                <section>
                    {showLogin ? <div className="login">
                        <header>
                            <h4>Z</h4>
                            <h3>Poker</h3>
                        </header>
                        <div className="form">
                            <span class="error">{error}</span>
                            <div className="formControl">
                                <label>Email</label>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" name="screen_name" />
                            </div>
                            <div className="formControl">
                                <label>Password</label>
                                <input onKeyDown={onPressEnter} value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" />
                            </div>
                            <div className="" style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                <a onClick={forgotPassword} style={{ color: '#ffb229' }} href="javascript:void(0)">Forgot your password?</a>
                                <button onClick={userLogin}>Login</button>
                            </div>
                        </div>
                    </div> : <div className="signup">
                            <h5>Create account</h5>
                            <div className="form">
                                {/* <span class="error">{error}</span> */}
                                <div className="formControl">
                                    <label>Name</label>
                                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" name="name" />
                                </div>
                                <div className="formControl">
                                    <label>Username</label>
                                    <input value={screen_name} onChange={(e) => setUserID(e.target.value)} type="text" name="screen_name" />
                                </div>
                                <div className="formControl">
                                    <label>Email</label>
                                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" />
                                </div>
                                <div className="formControl">
                                    <label>Password</label>
                                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" />
                                </div>
                                <div className="formControl">
                                    <label>Re-Type Password</label>
                                    <input value={rpassword} onChange={(e) => setRPassword(e.target.value)} type="password" name="rpassword" />
                                </div>
                                <span className="errorBottom">{error}</span>
                                <div className="" style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                                    <button disabled={!name || !screen_name || !email || !password} onClick={verifyPassword}>Signup</button>
                                </div>
                            </div>
                        </div>}
                    <div className="cut"></div>
                </section>
                <footer >
                    <div className="cut"></div>
                    {showLogin ? <div className="login" >
                        <label>Not a registered user?</label>
                        <button onClick={() => showBlock(false)} >Register for FREE</button>
                    </div> :
                        <div className="signup" >
                            <label>Already a member?</label>
                            <button onClick={() => showBlock(true)}>Login</button>
                        </div>}
                </footer>
            </main>
        </>
    )
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = dispatch => {
    return {
        loginAction: bindActionCreators(loginAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);