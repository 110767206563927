import React, { useState, useEffect } from 'react';
import ChatWindow from './ChatWindow';

function GameMenuBottom(props) {
    const {
        userinfo,
        table_id,
        club_name,
        table_info,
        allGameType
    } = props;

    const [gameType, setGameType] = useState('');
    const [activetab, setActiveTab] = useState('');
    const [chatMessage, setChatMessage] = useState([]);
    const [show_chat, setShowChatWindow] = useState(false);

    useEffect(() => {
        window.onclick = function (event) {
            if (event.target == document.getElementById("tabModal")) {
                document.getElementById("tabModal").style.display = "none";
            }
        }
        if (show_chat) {
            let history = document.getElementById('history');
            if (history) {
                history.scrollTop = history.scrollHeight;
                const element = document.querySelector('.chatBox');
                const minimum_size = 20;
                let original_width = 0;
                let original_height = 0;
                let original_x = 0;
                let original_y = 0;
                let original_mouse_x = 0;
                let original_mouse_y = 0;
                const resizers = document.querySelectorAll('.chatBox' + ' .resizer');
                for (let i = 0; i < resizers.length; i++) {
                    const currentResizer = resizers[i];
                    currentResizer.addEventListener('mousedown', function (e) {
                        e.preventDefault()
                        original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
                        original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
                        original_x = element.getBoundingClientRect().left;
                        original_y = element.getBoundingClientRect().top;
                        original_mouse_x = e.pageX;
                        original_mouse_y = e.pageY;
                        window.addEventListener('mousemove', resize)
                        window.addEventListener('mouseup', stopResize)
                    })
                    function resize(e) {
                        if (currentResizer.classList.contains('right_r')) {
                            const width = original_width + (e.pageX - original_mouse_x);
                            if (width > minimum_size) {
                                element.style.width = width + 'px'
                            }
                        }

                        else if (currentResizer.classList.contains('top_r')) {
                            const height = original_height - (e.pageY - original_mouse_y)
                            if (height > minimum_size) {
                                element.style.height = height + 'px'
                            }
                        }
                    }

                    function stopResize() {
                        window.removeEventListener('mousemove', resize)
                    }
                }
            }
        }
    }, [show_chat])

    const saveUserPref = () => {
        let data = {
            club_name: club_name,
            table_id: table_id,
            actor: userinfo && userinfo.screen_name,
            game_type: gameType
        }
        props.gameAction.setUserPreferences(data, (res) => {
            if (res.success) {
                setGameType(false);
                document.getElementById("tabModal").style.display = "none";
            }
        });
    }

    const onPressEnterForGT = (e) => {
        if (e.key === 'Enter' && gameType) {
            saveUserPref()
        }
    }

    if (table_info && table_info.hand_history && chatMessage !== table_info.hand_history) {
        setChatMessage(table_info.hand_history);
    }

    return (
        <>
            <div className="btnContainer" style={{ display: 'block' }}>
                {table_info && table_info.custom_game_allowed && <a onClick={() => {
                    document.getElementById("tabModal").style.display = "block";
                }} id="btnMaxScreen"><i className="fa fa-user-cog" aria-hidden="true"></i></a>}
                <a onClick={() => { setShowChatWindow(!show_chat); setActiveTab('chat') }} id="btnMaxScreen">
                    <i className="fa fa-comment-dots" aria-hidden="true"></i>
                </a>
            </div>
            <div id="tabModal" className="modal">
                <div className="modal-content" id="clubModal">
                    <span onClick={() => {
                        document.getElementById("tabModal").style.display = "none";
                    }} className="close">&times;</span>
                    <center><h3>{`SET YOUR PREFERENCES`}</h3></center>
                    <div className="formControl">
                        <label>Select Default Game Type</label>
                        <select onKeyDown={onPressEnterForGT} value={gameType} onChange={(e) => setGameType(e.target.value)}>
                            <option value={''}>{`---Select---`}</option>
                            {allGameType && allGameType.map((g, i) => <option key={`aop${i}`} value={g.id}>{g.name}</option>)}
                        </select>
                    </div>
                    <div className="lastPart">
                        <button onClick={() => saveUserPref()}>Save</button>
                    </div>
                </div>
            </div>
            {show_chat && <div className="chatBox">
                <div className="tab_row">
                    <div className={`chat_button ${activetab === 'chat' ? 'tab_active' : ''}`} onClick={() => setActiveTab('chat')}>
                        Chat
                    </div>
                    <div className={`chat_button  ${activetab === 'note' ? 'tab_active' : ''}`} onClick={() => setActiveTab('note')}>
                        Notes
                    </div>
                    <div className={`chat_button  ${activetab === 'stats' ? 'tab_active' : ''}`} onClick={() => setActiveTab('stats')}>
                        Stats
                    </div>
                    <div className={`chat_button  ${activetab === 'info' ? 'tab_active' : ''}`} onClick={() => setActiveTab('info')}>
                        Info
                    </div>
                    <span onClick={() => setShowChatWindow(false)} className="dot"> <i class="fa fa-window-close" aria-hidden="true"></i> </span>
                    <ChatWindow tableId={table_id} clubName={club_name} />
                    {/* <span onClick={() => openNewWindow()} className="dot">  <i class="fa fa-window-maximize" aria-hidden="true"></i> </span> */}
                </div>
                <div className="bottom_input">
                    <input placeholder={`Type your message here..`} type="text" />
                </div>
                <div id="history" className="chat_text">
                    {chatMessage && chatMessage.map((ch, i) => {
                        if (ch.type === 'create') {
                            return <div key={`ch${i}`} className="chat_action_create">hand # {ch.hand_count} ({ch.hand_id})</div>
                        }
                        if (ch.type === 'info') {
                            return <div key={`ch${i}`}></div>
                        }
                        if (ch.type === 'deal_card') {
                            return <div key={`ch${i}`} className="chat_action">
                                <span style={{ fontWeight: 'bold', margin: '10px 0px', textTransform: 'uppercase' }}>***** {ch.deal_type} *****</span> <br />
                                <span className="chat_title">Dealer:</span>{` ${ch.card_count} card dealt. ${ch.cards.toString()}`}
                            </div>
                        }
                        if (ch.type === 'action') {
                            return <div key={`ch${i}`} className="chat_action">
                                <span className="chat_title">{ch.name}:</span>{` ${ch.action} ${ch.chips ? ch.chips : ''}`}
                            </div>
                        }
                        if (ch.type === 'hand_winner') {
                            return <table key={`ch${i}`} id="chat_table" dangerouslySetInnerHTML={{ __html: ch.html }}></table>
                            // return <div id="tables" dangerouslySetInnerHTML={{ __html: ch.html }}></div>
                        }
                    })}
                </div>
                {/* <div className='resizer top_r'></div>
                <div className='resizer right_r'></div>
                <div className="top">
                    <span onClick={() => openNewWindow()} className="dot">  <i class="fa fa-window-maximize" aria-hidden="true"></i> </span>
                    <span onClick={() => setShowChatWindow(false)} className="dot"> <i class="fa fa-window-close" aria-hidden="true"></i> </span>
                </div>
                <div id="history" className="chat_text">
                    {chatMessage && chatMessage.map((ch, i) => {
                        if (ch.type === 'create') {
                            return <div key={`ch${i}`} className="chat_action_create">hand # {ch.hand_count} ({ch.hand_id})</div>
                        }
                        if (ch.type === 'info') {
                            return <div key={`ch${i}`}></div>
                        }
                        if (ch.type === 'deal_card') {
                            return <div key={`ch${i}`} className="chat_action">
                                <span className="chat_title">{ch.deal_type}:</span>{` ${ch.card_count} card dealt. ${ch.cards.toString()}`}
                            </div>
                        }
                        if (ch.type === 'action') {
                            return <div key={`ch${i}`} className="chat_action">
                                <span className="chat_title">{ch.name}:</span>{` ${ch.action} ${ch.chips ? ch.chips : ''}`}
                            </div>
                        }
                        if (ch.type === 'hand_winner') {
                            return <table key={`ch${i}`} id="chat_table" dangerouslySetInnerHTML={{ __html: ch.html }}></table>
                            // return <div id="tables" dangerouslySetInnerHTML={{ __html: ch.html }}></div>
                        }
                    })}
                    <div></div>
                </div>

                */}
            </div>}
        </>
    )
}

const GameMenuBottomMemo = React.memo(GameMenuBottom);
export default GameMenuBottomMemo;