import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import * as loginAction from '../redux/actions/login-action';
import * as gameAction from '../redux/actions/game-action';
import * as clubAction from '../redux/actions/club-action';

import * as Animation from '../config/CardShuffleArrangs';

import ActionButtons from './game/ActionButtons';
import GameMenuBottomMemo from './game/GameMenuBottom';
import ChipsInfoPotAndPlayerMemo from './game/ChipsInfoPotAndPlayer';
import AddChipsMemo from './game/AddChips';
import GameTableMemo from './game/GameTable';
import GameInfoMemo from './game/GameInfo';
import TablePlayerMemo from './TablePlayer';

import '../assets/css/ontable.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
import firebase from "firebase";

let handId = null;
let tableId = null;
let clubName = null;
let distributedCard = false;
let card_changed = false;
let card_arrange = false;
let handData = null;
let userData = null;
let winner_pot_dis = false;
let current_step = null;
let current_game_info = null;
let set_card_position = true;
let testHandInfo = {};
let before_change_pl_cards = []
// let raise_amt = 0;
// let bet_amt = 0;

let selCardList = []
let sitInInfo = null;
let card_arr_index_dum = {
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
}

function OnTable(props) {
    const [chips, setChips] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [handInfo, setHandInfo] = useState(null);
    const [raise_amt, setRaiseAmt] = useState(null);
    const [bet_amt, setBetAmt] = useState(null);
    const [tableInfo, setTableInfo] = useState(null);
    const [allGameType, setAllGameType] = useState(null);
    const [allPlayers, setAllPlayers] = useState(null);
    const [countDownStart, setCountDownStart] = useState(null);
    const [allActivePlayers, setAllActivePlayers] = useState(null);

    const tableSnapRef = (club_name, table_id) => {
        return firebase.firestore().collection('clubs').doc(club_name).collection('tables').doc(table_id)
    }

    const plSnapRef = (club_name, table_id) => {
        return firebase.firestore().collection('clubs').doc(club_name).collection('tables').doc(table_id).collection('players')
    }

    const handSnapRef = (club_name, table_id, hand_id) => {
        return firebase.firestore().collection('clubs').doc(club_name).collection('tables').doc(table_id).collection('hands').doc(hand_id)
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        clubName = urlParams.get('club_name');
        tableId = urlParams.get('table_id');

        Animation.initializeDeck();

        let allActivePlayer = allActivePlayers;
        const unsubscribe_pl_snap = plSnapRef(clubName, tableId).onSnapshot((pl_snap) => {
            let pl_data = pl_snap.docs.map((doc) => doc.data());
            setAllPlayers(pl_data)
            allActivePlayer = pl_data.filter(d => d.status !== 'join')
            setAllActivePlayers(pl_data.filter(d => d.status !== 'join'))
        });

        let unsubscribe_hand_snap = null;
        let unsubscribe_table_snap = null;

        let userInfo = localStorage.getItem('user');
        const getUserInfo = (uid) => {
            props.clubAction.getUserInfo(uid, (ures) => {
                setUserInfo(ures.data);
                userData = ures.data;
                unsubscribe_table_snap = tableSnapRef(clubName, tableId).onSnapshot((res) => {
                    setTableInfo(res.data())
                    if (res.data() && res.data().hand_about_to_start) {
                        let count = res.data().start_in_seconds;
                        if (document.getElementById("lblTableStatus")) {
                            document.getElementById("lblTableStatus").innerHTML = ``
                        }
                        let sIc = setInterval(() => {
                            let c = count - 1
                            let text = 'Game About to start in ' + c + ' seconds'
                            setCountDownStart(text);
                            count = count - 1;
                            if (count === 0) {
                                let text = 'Game is starting, Please Wait...'
                                setCountDownStart(text);
                                clearInterval(sIc)
                            }
                        }, 1000)
                    } else {
                        setCountDownStart(null);
                    }
                    if (res.data() && res.data().current_hand) {
                        if (handId && handId !== res.data().current_hand) {
                            distributedCard = false;
                            winner_pot_dis = false;
                            current_step = null;
                            set_card_position = true;
                            Animation.Resetcards();
                        }
                        unsubscribe_hand_snap = handSnapRef(clubName, tableId, res.data().current_hand).onSnapshot((hand) => {
                            handId = res.data().current_hand;
                            let hand_info = hand.data();
                            if (handInfo !== hand_info) {
                                handData = hand_info;
                                setHandInfo(hand_info);
                                if (hand_info.game_type && set_card_position) {
                                    getGameTypeById(hand_info.game_type)
                                }
                                // current_step = null;
                                if (hand_info.players_card && !distributedCard) {
                                    let arrang_line = hand_info.arrangement_type && hand_info.arrangement_type.length > 0 ? true : false;
                                    if(hand_info.current_step !== 'evaluating_hand') {
                                        Animation.ArrangeDeck(hand_info.players_card, ures.data.screen_name, hand_info.deal_player_cards, onCardSelect, arrang_line);
                                    }
                                    distributedCard = true;
                                    let curr_pl = allActivePlayer.filter(p => p.name === ures.data.screen_name)
                                    window.$(function () {
                                        if (curr_pl.length > 0) {
                                            window.$(`#plc${curr_pl[0].seat_no}`).sortable({
                                                update: function (event, ui) {
                                                    // console.log("==--=>> ", event, ui)
                                                },
                                                stop: function (event, ui) {
                                                    if (hand_info.current_step !== 'evaluating_hand' && hand_info.arrangement_type && hand_info.arrangement_type.length > 0) {
                                                        let itemOrder = window.$(`#plc${curr_pl[0].seat_no}`).sortable("toArray");
                                                        itemOrder = itemOrder.filter((it) => it !== "");

                                                        let card_arr_index = { ...card_arr_index_dum }

                                                        for (var i = 1; i <= itemOrder.length; i++) {
                                                            card_arr_index[i] = itemOrder[i - 1]
                                                        }
                                                        let fil_card_arr = Object.entries(card_arr_index).filter((key) => key[1]).reduce((a, k) => ({ ...a, [k[0]]: k[1] }), {})

                                                        let data = {
                                                            table_id: tableId,
                                                            club_name: clubName,
                                                            actor: ures.data.screen_name,
                                                            hand_id: res.data().current_hand,
                                                            shuffled_card: fil_card_arr
                                                        }
                                                        props.gameAction.saveCardShuffle(data, () => { });
                                                    }
                                                }
                                            });
                                            window.$(`#plc${curr_pl[0].seat_no}`).disableSelection();
                                        }
                                    });
                                }

                                if (hand_info.current_step === 'pot_collection' && current_step !== 'pot_collection') {
                                    current_step = 'pot_collection'
                                    Animation.animateSidePotToCenter();
                                }


                                if (hand_info.animation && testHandInfo.animation !== hand_info.animation && hand_info.animation.chips_to_animate && (!testHandInfo.animation || testHandInfo.animation.actor !== hand_info.animation.actor)) {
                                    testHandInfo = hand_info;
                                    Animation.animateChipsFromPlayerHandToBet(hand_info.animation.chips_to_animate)
                                }

                                if (!hand_info.animation) {
                                    testHandInfo = hand_info;
                                }

                                if (hand_info && hand_info.card_change_metadata && hand_info.current_step === 'card_change' && !card_changed) {
                                    before_change_pl_cards = hand_info.players_card;
                                    card_changed = true;
                                    onCardChangeEvent()
                                }

                                if (hand_info.card_arranged && card_arrange) {
                                    card_arrange = false;
                                    var betControl = document.getElementById('ControlBar');
                                    var ccDiv = document.getElementById('AutoControls_CC');
                                    if (ccDiv) {
                                        betControl.removeChild(ccDiv);
                                    }
                                }

                                if (hand_info && !hand_info.card_arranged && hand_info.arrange_cards_metadata && hand_info.current_step === 'arrange_card' && !card_arrange && hand_info.current_step !== 'evaluating_hand') {
                                    card_arrange = true;
                                    onCardArrangeEvent()
                                }


                                if (hand_info.card_changed && card_changed) {
                                    card_changed = false
                                    let sel_card = selCardList;
                                    if (sel_card.length > 0) {
                                        sel_card.map((se, i) => {
                                            let card = document.getElementsByClassName(`${se} dis_card`);
                                            if (card.length > 0) {
                                                card[0].style.boxShadow = 'none';
                                                card[0].style.transform = 'none';
                                                card[0].style.border = 'none';
                                            }
                                        })
                                        selCardList = [];
                                    }
                                    Animation.AnimateAfterCardChange(hand_info.players_card, userData.screen_name, before_change_pl_cards, onCardSelect)
                                }
                                if (hand_info.current_step === 'evaluating_hand' && current_step !== 'evaluating_hand') {
                                    current_step = hand_info.current_step;
                                    Animation.showCards(hand_info.players_card);
                                }

                                if (hand_info && hand_info.hand_winner && typeof (hand_info.hand_winner) === "object" && !winner_pot_dis) {
                                    winner_pot_dis = true;
                                    if (!document.getElementById("mytable")) {
                                        appendHandWinner(hand_info.hand_winner, allActivePlayer);
                                    }
                                }

                                setRaiseAmt(hand_info.raise_amount ? hand_info.raise_amount.min_raise : 0)
                                setBetAmt(hand_info.round_next_call ? hand_info.round_next_call : 0)
                            }
                        })
                    }
                })
            })
        }

        getUserInfo(userInfo);

        props.gameAction.getAllGameType((res) => {
            if (res !== 'error' && res.status == 200) {
                setAllGameType(res.data)
            }
        })

        window.onclick = function (event) {
            if (event.target == document.getElementById("tabModal")) {
                document.getElementById("tabModal").style.display = "none";
            }
        }

        return () => {
            unsubscribe_pl_snap();
            if (typeof (unsubscribe_table_snap) === 'function') {
                unsubscribe_table_snap();
            }
            if (typeof (unsubscribe_hand_snap) === 'function') {
                unsubscribe_hand_snap();
            }
            distributedCard = false;
        }
    }, [])

    const getGameTypeById = async (game_type) => {
        set_card_position = false;
        props.gameAction.getGameTypeByID(game_type, (res) => {
            if (res !== 'error' && res.status == 200) {
                current_game_info = res.data;
                if (res.data) {
                    getAllCardsPosition(res.data)
                }
            }
        })
    }

    const getAllCardsPosition = (info) => {
        let arrangements = []
        if (info.game_steps) {
            info.game_steps.map((gi) => {
                if (gi.arrangement && !gi.change_card) {
                    let d = Object.values(gi.arrangement);
                    arrangements.push(...d);
                }
                if (gi.change_card) {
                    arrangements.push({
                        ...gi.arrangement,
                        card_change: true,
                        count: gi.max_card_change
                    });
                }
            })
        }
        Animation.showDummyTableCards(arrangements)
    }

    const appendHandWinner = (hand_winner, allActivePlayer) => {
        hand_winner.map(async (h) => {
            let board_info = []
            let board_summary = await Object.entries(h.boards).map((e, i) => ({ ...e[1] }));
            await board_summary.map((e, i) => e[`board${i + 1}`].map((b) => board_info.push(b)));
            await board_info.map((b, i) => {
                if (b.chips) {
                    setTimeout(() => {
                        Animation.AnimateAndDistributeCoins(i, b.chips, b.name, allActivePlayer);
                    }, (i + 1) * 500)
                }
            })
        })
    }

    const onCardArrangeEvent = () => {
        // const { table_id, club_name, userinfo, hand_id, selCardList, hand_info } = this.state;
        var feltDivBtn = document.getElementById('AutoControls_CC');

        if (!feltDivBtn) {
            var betControl = document.getElementById('ControlBar');
            var ccDiv = document.createElement("div");
            betControl.appendChild(ccDiv);
            ccDiv.id = "AutoControls_CC";
            ccDiv.style.display = "flex";
            ccDiv.style.justifyContent = "center";
            ccDiv.style.alignItems = "center";
            ccDiv.style.width = "69vw";
            ccDiv.style.textTransform = "uppercase";
            var aCC = document.createElement("a");
            aCC.id = 'btnAutoFold';
            aCC.innerHTML = `<label class="small"></label><label>Done</label>`
            aCC.onclick = () => {
                let data = {
                    table_id: tableId,
                    club_name: clubName,
                    actor: userData && userData.screen_name,
                    hand_id: handId,
                }
                props.gameAction.cardToArrange(data, () => { });
                betControl.removeChild(ccDiv);
            }
            var textDiv = document.createElement("a");
            textDiv.style.textTransform = "none";
            textDiv.innerText = "Please arrange your cards and click ";
            textDiv.style.marginRight = '5px';
            ccDiv.appendChild(textDiv)
            ccDiv.appendChild(aCC)
        }
    }

    const onCardChangeEvent = () => {
        // const { table_id, club_name, userinfo, hand_id, selCardList, hand_info } = this.state;
        var feltDivBtn = document.getElementById('AutoControls_CC');
        let { pl_max_card_to_change, card_change_cost, card_changed_count } = handData.players[userData.screen_name]

        if (!feltDivBtn) {
            var betControl = document.getElementById('ControlBar');
            var ccDiv = document.createElement("div");
            betControl.appendChild(ccDiv);
            ccDiv.id = "AutoControls_CC";
            ccDiv.style.display = "flex";
            ccDiv.style.justifyContent = "center";
            ccDiv.style.alignItems = "center";
            ccDiv.style.width = "69vw";
            ccDiv.style.textTransform = "uppercase";
            var aCC = document.createElement("a");
            aCC.id = 'btnAutoFold';
            aCC.innerHTML = `<label class="small"></label><label>Done</label>`
            aCC.onclick = () => {
                let data = {
                    table_id: tableId,
                    club_name: clubName,
                    actor: userData && userData.screen_name,
                    hand_id: handId,
                    cardtochange: selCardList
                }
                props.gameAction.cardToChange(data, () => { });
                selCardList = []
                betControl.removeChild(ccDiv);
                var cDiv = document.createElement("div");
                betControl.appendChild(cDiv);
                cDiv.id = "AutoControls_CC";
                cDiv.style.display = "flex";
                cDiv.style.justifyContent = "center";
                cDiv.style.alignItems = "center";
                cDiv.style.width = "69vw";
                cDiv.style.textTransform = "uppercase";
                cDiv.style.height = '3vw';
                var textDi = document.createElement("a");
                textDi.style.textTransform = "none";
                textDi.innerText = "Please wait till all player changed card.";
                textDi.style.marginRight = '5px';
                cDiv.appendChild(textDi)
            }

            var textDiv = document.createElement("a");
            textDiv.style.textTransform = "none";
            textDiv.innerText = "Select up to " + pl_max_card_to_change + " card to change and click ";
            textDiv.style.marginRight = '5px';
            var cardCostDiv = document.createElement("a");
            cardCostDiv.innerHTML = `<span id="card_cst"></span>`
            ccDiv.appendChild(textDiv)
            ccDiv.appendChild(aCC)
            ccDiv.appendChild(cardCostDiv)
        }
    }
    const onCardSelect = (selCard) => {
        let sel_card = selCardList;
        let { pl_max_card_to_change, card_change_cost, card_changed_count } = handData.players[userData.screen_name]

        let card_change_id = document.getElementById('card_cst');
        if (handData.current_step == 'card_change' && card_change_id) {
            console.log(selCard)
            let card = document.getElementById(`${selCard}`);
            if (sel_card.indexOf(selCard) === -1 && selCardList.length < pl_max_card_to_change) {
                console.log(card)
                card.style.boxShadow = 'rgba(0, 0, 0, 0.22) 0px 19px 43px'
                card.style.transform = 'translate3d(0px, -10px, 0px)'
                card.style.border = `1px solid red`
                sel_card.push(selCard);
                card_change_id.innerHTML = card_change_cost[selCardList.length - 1] ? `(Card Cost: ${card_change_cost[selCardList.length - 1]}   <img style="height: 2vh" src=${require('../assets/chips-icon-small.png')} /> )` : ``
                selCardList = sel_card;
            } else if (sel_card.indexOf(selCard) > -1) {
                card.style.boxShadow = 'none'
                card.style.transform = 'none'
                card.style.border = 'none'
                sel_card.splice(sel_card.indexOf(selCard), 1)
                card_change_id.innerHTML = card_change_cost[selCardList.length - 1] ? `(Card Cost: ${card_change_cost[selCardList.length - 1]}   <img style="height: 2vh" src=${require('../assets/chips-icon-small.png')} /> )` : ``
                selCardList = sel_card;
            } else {

            }
        }
    }

    const onPressSitIn = (seatno, screen_name) => {
        let curr_pl = allPlayers.filter(d => d.name == screen_name)
        if (curr_pl.length > 0 && curr_pl[0].chips > 0) {
            let data = {
                seatno: seatno,
                table_id: tableId,
                club_name: clubName,
                actor: userInfo && userInfo.screen_name,
                action: 'sitin'
            }
            props.gameAction.saveCurrentState(data, () => { });
            return true;
        }
        if (curr_pl.length > 0 && curr_pl[0].chips <= 0) {
            sitInInfo = {
                seatno: seatno,
                screen_name: screen_name
            }
            document.getElementById("buyModalT").style.display = "block";
        }
    }

    const markSitoutFromNextHand = () => {
        let data = {
            table_id: tableId,
            club_name: clubName,
            name: userInfo.screen_name
        }
        props.clubAction.markSitoutFromNextHand(data, () => {

        })
    }

    const onClickActionBtn = (action, seat_no) => {
        if (action === 'bet' || action === 'call') {
            callPlayerAction(action, bet_amt, seat_no)
            return true;
        }
        if (action === 'raise') {
            callPlayerAction(action, raise_amt, seat_no)
            return true;
        }
        callPlayerAction(action, null, seat_no)
        return true;
    }

    const callPlayerAction = (action, chips, seat_no) => {
        let data = {
            table_id: tableId,
            club_name: clubName,
            action: action,
            actor: handInfo.action_metadata.action_to,
            chips: chips,
            hand_id: handId,
            action_id: handInfo.action_metadata.id,
            player_name: userInfo.screen_name,
            seat_no: seat_no
        }
        props.gameAction.saveCurrentState(data, (res) => {
        });
    }

    const onPressEnterForBuyChips = (e) => {
        if (e.key === 'Enter' && chips) {
            buyChips()
        }
    }

    const buyChips = () => {
        let data = {
            chips: chips,
            firebase_id: userInfo.firebase_id,
            club_name: clubName,
            table_id: tableId,
            name: userInfo.screen_name
        }
        props.clubAction.addChipsToTable(data, sitInInfo.seatno, (res) => {
            // this.setState({
            //     chips: 0
            // })
            document.getElementById("buyModalT").style.display = "none";
        })
    }

    const incrementOrDecrementAmtRaise = (type, player) => {
        if (type === 'increment') {
            let valueToAdd = raise_amt + handInfo.big_value;
            if (valueToAdd <= handInfo.players[player].chips_in_hand) {
                setRaiseAmt(valueToAdd)
            }
            return;
        }

        let valueToDec = raise_amt - handInfo.big_value;

        if (valueToDec >= handInfo.raise_amount.min_raise) {
            setRaiseAmt(valueToDec)
        }
        return;
    }

    const incrementOrDecrementAmtBet = (type, player) => {
        if (type === 'increment') {
            let valueToAdd = bet_amt + handInfo.big_value;
            if (valueToAdd <= handInfo.players[player].chips_in_hand) {
                setBetAmt(valueToAdd)
            }
            return;
        }

        let valueToDec = bet_amt - handInfo.big_value;

        if (valueToDec >= handInfo.round_next_call) {
            setBetAmt(valueToDec)
        }
        return;
    }

    const changeBetAmt = (value, player) => {
        if (value <= handInfo.players[player].chips_in_hand && value >= handInfo.round_next_call) {
            setBetAmt(value);
            return;
        }
        return;
    }

    const changeRaiseAmt = (value, player) => {
        if (value <= handInfo.players[player].chips_in_hand && value >= handInfo.raise_amount.min_raise) {
            setRaiseAmt(value);
            return;
        }
        return;
    }

    return (
        <div id="ontable">
            {clubName && userInfo && <GameInfoMemo
                {...props}
                hand_id={handId}
                club_name={clubName}
                table_id={tableId}
                userinfo={userInfo}
                hand_info={handInfo}
                gameAction={props.gameAction}
            />}
            {handInfo && <ChipsInfoPotAndPlayerMemo
                hand_info={handInfo}
                player_info={allActivePlayers}
            />}
            {userInfo && allActivePlayers && <AddChipsMemo
                table_id={tableId}
                userinfo={userInfo}
                club_name={clubName}
                player_info={allActivePlayers}
                clubAction={props.clubAction}
            />}
            <TablePlayerMemo
                userinfo={userInfo}
                club_name={clubName}
                table_id={tableId}
                hand_info={handInfo}
                player_info={allActivePlayers}
                onPressSitIn={onPressSitIn}
                clubAction={props.clubAction}
            />

            {handInfo && handInfo.rake_chips && <div className="all_pot_stack" style={{ top: '56.4vh', zIndex: 999999, position: 'absolute' }}>
                <span style={{ color: 'white', fontWeight: 'bolder', fontSize: '1.8vh' }}>{handInfo.rake_chips} </span>
            </div>}

            <div className="gametable">
                <div id="mydiv">
                    <GameTableMemo table_cards={handInfo && handInfo.table_cards ? handInfo.table_cards : []} />
                </div>
            </div>
            <div id="ControlBar" className="">
                <GameMenuBottomMemo
                    {...props}
                    table_id={tableId}
                    club_name={clubName}
                    table_info={tableInfo}
                    allGameType={allGameType}
                    userinfo={userInfo}
                    gameAction={props.gameAction}
                />
                <div className="background"></div>
                {handInfo && userInfo && handInfo.action_metadata && handInfo.action_metadata.action_to === userInfo.screen_name &&
                    <div id="AutoControls" className="hidden" style={{ display: 'flex', textTransform: 'uppercase', width: '69vw', justifyContent: 'center' }}>
                        <ActionButtons
                            raise={raise_amt}
                            bet={bet_amt}
                            hand_info={handInfo}
                            userInfo={userInfo}
                            changeBetAmt={changeBetAmt}
                            changeRaiseAmt={changeRaiseAmt}
                            onClickActionBtn={onClickActionBtn}
                            incrementOrDecrementAmtRaise={incrementOrDecrementAmtRaise}
                            incrementOrDecrementAmtBet={incrementOrDecrementAmtBet}
                        />
                    </div>}
                {tableInfo && tableInfo.hand_about_to_start &&
                    <div id="AutoControls" className="hidden" style={{ display: 'flex', textTransform: 'uppercase', width: '69vw', justifyContent: 'center' }}>
                        <div style={{ height: '30px' }}>{countDownStart}</div>
                    </div>}
                {handInfo && handInfo.hand_winner && typeof (handInfo.hand_winner) !== "object" && <div id="TableStatus">
                    <div id="lblTableStatus">Hand Winner: {handInfo.hand_winner.toString()}</div>
                </div>}
            </div>
            {userInfo && allActivePlayers &&
                <div className="sitinout">
                    {(allActivePlayers.filter((p) => p.name === userInfo.screen_name && (p.status === 'sitout' || p.sitout_next_hand)).length > 0) ?
                        <div>
                            <button id="add" onClick={() => onPressSitIn(allActivePlayers.filter((p) => p.name === userInfo.screen_name)[0].seat_no, userInfo.screen_name)}>
                                <label className="small"></label>
                                <label>SitIn</label>
                            </button>
                        </div> : (allActivePlayers.filter((p) => p.name === userInfo.screen_name).length > 0) ?
                            <div>
                                <input style={{ cursor: 'pointer' }} type="checkbox" onChange={markSitoutFromNextHand} />
                                <span style={{ cursor: 'pointer' }} onClick={markSitoutFromNextHand} className="sitinout_text">
                                    {`Sit out next hand`}
                                </span>
                            </div> : null}
                </div>}
            <div id="buyModalT" className="modal">
                <div className="modal-content" id="clubModal" style={{minHeight:'240px'}}>
                    <span onClick={() => {
                        document.getElementById("buyModalT").style.display = "none";
                    }} className="close">&times;</span>
                    <center><h3>{`Add Chips to Table`}</h3></center>
                    <div className="form">
                        <div className="formControl">
                            <label>Enter Chips Count</label>
                            <input onKeyDown={onPressEnterForBuyChips} value={chips} onChange={(e) => setChips(e.target.value)} type="text" />
                        </div>
                        <div className="lastPart">
                            <button onClick={() => buyChips()}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        loginAction: bindActionCreators(loginAction, dispatch),
        gameAction: bindActionCreators(gameAction, dispatch),
        clubAction: bindActionCreators(clubAction, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(OnTable);