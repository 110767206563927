// import { SET_FILTER } from "../actionTypes";

const loginReducer = (state = null, action) => {
    switch (action.type) {
        case 'SET_USER_DATA': {
            return {
                ...state,
                user: action.payload.user
            }
        }
        case 'SET_GAME_DATA': {
            return {
                ...state,
                game: action.payload.game
            }
        }
        case 'SET_GAME_CARDS': {
            return {
                ...state,
                cards: action.payload.cards
            }
        }
        case 'SET_GAME_TYPE_LIST': {
            return {
                ...state,
                game_type: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default loginReducer;