import React, { useEffect, useState, useRef } from 'react';
import firebase from "firebase";
import NewWindow from 'react-new-window'

let data = null;

function ChatWindow(props) {
    const inputEl = useRef(null);
    const hrefEl = useRef(null);

    const [tableInfo, setTableInfo] = useState([]);
    const [newWindow, setNewWindow] = useState(false);

    useEffect(() => {
        let clubName = props.clubName;
        let tableId = props.tableId;

        let unsubscribe_table_snap = tableSnapRef(clubName, tableId).onSnapshot((res) => {
            setTableInfo(res.data())
        })

        // return unsubscribe_table_snap();
    }, [])

    const tableSnapRef = (club_name, table_id) => {
        return firebase.firestore().collection('clubs').doc(club_name).collection('tables').doc(table_id)
    }

    const openNewWindow = () => {
        setNewWindow(true);
    }

    const fnExcelReport = (table, name, filename) => {
        // var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
        // var textRange; var j = 0;
        // let txtArea1 = null;
        // let sa = null;
        // let tab = inputEl.current; // id of table
        // // console.log("=--=> ", inputEl.current)
        // // console.log("=--=> ", inputEl.current)

        // for (j = 0; j < tab.rows.length; j++) {
        //     tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
        //     //tab_text=tab_text+"</tr>";
        // }

        // tab_text = tab_text + "</table>";
        // tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "");//remove if u want links in your table
        // tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
        // tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

        // var ua = window.navigator.userAgent;
        // var msie = ua.indexOf("MSIE ");

        // if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // If Internet Explorer
        // {
        //     txtArea1.document.open("txt/html", "replace");
        //     txtArea1.document.write(tab_text);
        //     txtArea1.document.close();
        //     txtArea1.focus();
        //     sa = txtArea1.document.execCommand("SaveAs", true, "Say Thanks to Sumit.xls");
        // }
        // else                 //other browser not tested on IE 11
        //     sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text));

        // return (sa);

         // (function (table, name, filename) {
            var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
            , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
            , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
            // return function (table, name, filename) {
                // if (!table.nodeType) table = document.getElementById(table)
                var ctx = { worksheet: name || 'Worksheet', table: inputEl.current }
                let aPath = hrefEl.current
    
                aPath.href = uri + base64(format(template, ctx));
                aPath.download = filename;
                aPath.click();
    
            // }
        // })(table, name, filename)
    }

    if (newWindow) {
        return (
            <NewWindow>
                <a id="dlink" ref={hrefEl}  style={{display: 'none'}}></a>
                <span id="excelExport" onClick={() => fnExcelReport()} className="dot">  <i class="fa fa-window-maximize" aria-hidden="true"></i> </span>
                <table ref={inputEl} id="history_full" className="chat_text">

                    {tableInfo && tableInfo.hand_history && tableInfo.hand_history.map((ch, i) => {
                        if (ch.type === 'create') {
                            return <tr key={`ch${i}`} className="chat_action_create">hand # {ch.hand_count} ({ch.hand_id})</tr>
                        }
                        if (ch.type === 'info') {
                            return <div key={`ch${i}`}></div>
                        }
                        if (ch.type === 'deal_card') {
                            return <tr key={`ch${i}`} className="chat_action">
                                <span style={{ fontWeight: 'bold', margin: '10px 0px', textTransform: 'uppercase' }}>***** {ch.deal_type} *****</span> <br />
                                <span className="chat_title">Dealer:</span>{` ${ch.card_count} card dealt. ${ch.cards.toString()}`}
                            </tr>
                        }
                        if (ch.type === 'action') {
                            return <tr key={`ch${i}`} className="chat_action">
                                <span className="chat_title">{ch.name}:</span>{` ${ch.action} ${ch.chips ? ch.chips : ''}`}
                            </tr>
                        }
                        // if (ch.type === 'hand_winner') {
                        //     return <table key={`ch${i}`} id="chat_table" dangerouslySetInnerHTML={{ __html: ch.html }}></table>
                        //     // return <div id="tables" dangerouslySetInnerHTML={{ __html: ch.html }}></div>
                        // }
                    })}
                </table>
            </NewWindow>
        )
    }

    return (<span onClick={() => openNewWindow()} className="dot">  <i class="fa fa-window-maximize" aria-hidden="true"></i> </span>)

}


export default ChatWindow;