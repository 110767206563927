import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import * as clubAction from '../../redux/actions/club-action';

function AddChips(props) {

    const {
        player_info, userinfo, club_name, table_id
    } = props;

    useEffect(() => {
        window.onclick = function (event) {
            if (event.target == document.getElementById("buyModal")) {
                document.getElementById("buyModal").style.display = "none";
            }
        }
    }, [])

    const [chips, setChipsCount] = useState("");
    const [addBtn, showAddBtn] = useState(false);

    let curr_pl = []
    if (player_info && userinfo) {
        curr_pl = player_info.filter((pl) => pl.name === userinfo.screen_name);
        if(curr_pl.length > 0 && curr_pl[0].status !== 'join' && !addBtn) {
            showAddBtn(true);
        }
        if(curr_pl.length > 0 && curr_pl[0].chips <= 0 && curr_pl[0].status !== 'sitin') {
            document.getElementById("buyModal").style.display = "block";
        }
    }

    const onPressEnterForBuyChips = (e) => {
        if (e.key === 'Enter' && chips) {
            buyChips()
        }
    }

    const buyChips = () => {
        let data = {
            chips: chips,
            firebase_id: userinfo.firebase_id,
            club_name: club_name,
            table_id: table_id,
            name: userinfo.screen_name
        }
        props.clubAction.addChipsToTable(data, null, (res) => {
            setChipsCount('');
            document.getElementById("buyModal").style.display = "none";
        })
    }

    return (
        <div>
            {addBtn && <div style={{ position: 'absolute', right: '5px', top: '10px' }}>
               
                <button id="add" onClick={() => {
                        document.getElementById("buyModal").style.display = "block";
                    }}>
                    <label className="small"></label>
                    <label>Add Chips</label>
                </button>
             
            </div>}

            <div id="buyModal" className="modal">
                <div className="modal-content" id="clubModal" style={{minHeight:'240px'}}>
                    <span onClick={() => {
                        document.getElementById("buyModal").style.display = "none";
                    }} className="close">&times;</span>
                    <center><h3>{`Add Chips to Table`}</h3></center>
                    <div className="form">
                        <div className="formControl">
                            <label>Enter Chips Count</label>
                            <input onKeyDown={onPressEnterForBuyChips} value={chips} onChange={(e) => setChipsCount(e.target.value)} type="text" />
                        </div>
                        <div className="lastPart">
                            <button onClick={() => buyChips()}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AddChipsMemo = React.memo(AddChips);
export default AddChipsMemo;