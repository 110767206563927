export const seat0 = {
    // height: '150px',
    position: 'absolute',
    // width: '150px',
    zIndex: '5',
    left: '10vw',
    top: '16vh',
    // display: 'flex',
    // flexDirection: 'column',
}
export const seat1 = {
    // height: '150px',
    position: 'absolute',
    // width: '150px',
    zIndex: '5',
    left: '28vw',
    top: '3vh',
    display: 'flex',
    flexDirection: 'column',
}
export const seat2 = {
    // height: '150px',
    position: 'absolute',
    // width: '150px',
    zIndex: '5',
    left: '65vw',
    top: '3vh',
    display: 'flex',
    flexDirection: 'column',
}
export const seat3 = {
    // height: '150px',
    position: 'absolute',
    // width: '150px',
    zIndex: '5',
    left: '78vw',
    top: '16vh',
    display: 'flex',
    flexDirection: 'column',
}
export const seat4 = {
    // height: '150px',
    position: 'absolute',
    // width: '150px',
    zIndex: '5',
    left: '78vw',
    top: '55vh',
    display: 'flex',
    flexDirection: 'column',
}
export const seat5 = {
    // height: '150px',
    position: 'absolute',
    // width: '150px',
    zIndex: '5',
    left: '64vw',
    top: '70vh',
    display: 'flex',
    flexDirection: 'column',
}
export const seat6 = {
    // height: '150px',
    position: 'absolute',
    // width: '150px',
    zIndex: '5',
    left: '44vw',
    top: '70vh',
    display: 'flex',
    flexDirection: 'column',
}
export const seat7 = {
    position: 'absolute',
    zIndex: '5',
    left: '24vw',
    top: '70vh',
    display: 'flex',
    flexDirection: 'column',
}
export const seat8 = {
    // height: '150px',
    position: 'absolute',
    // width: '150px',
    zIndex: '5',
    left: '10vw',
    top: '55vh',
    display: 'flex',
    flexDirection: 'column',
}

// Dealer Button Position

export const dealer0 = {
    width: '24px',
    height: '22px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '99999',
    color: '#000',
    left: '22vw',
    top: '30vh'
}
export const dealer1 = {
    width: '24px',
    height: '22px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '99999',
    color: '#000',
    left: '30vw',
    top: '22vh'
}
export const dealer2 = {
    width: '24px',
    height: '22px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '99999',
    color: '#000',
    left: '68vw',
    top: '22vh'
}
export const dealer3 = {
    width: '24px',
    height: '22px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '99999',
    color: '#000',
    left: '76vw',
    top: '30vh'
}
export const dealer4 = {
    width: '24px',
    height: '22px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '99999',
    color: '#000',
    left: '76vw',
    top: '57vh'
}
export const dealer5 = {
    width: '24px',
    height: '22px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '99999',
    color: '#000',
    left: '68vw',
    top: '65vh'
}
export const dealer6 = {
    width: '24px',
    height: '22px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '99999',
    color: '#000',
    left: '50vw',
    top: '65vh'
}
export const dealer7 = {
    width: '24px',
    height: '22px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '99999',
    color: '#000',
    left: '30vw',
    top: '65vh'
}
export const dealer8 = {
    width: '24px',
    height: '22px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '99999',
    color: '#000',
    left: '22vw',
    top: '57vh'
}

// Pot chips placement

export const pot0 = {
    padding: '3px',
    // borderRadius: '100px',
    position: 'absolute',
    textAlign: 'center',
    left: '24vw',
    top: '29vh',
    display: 'flex',
    flexDirection: 'column'

}
export const pot1 = {
    // borderRadius: '100px',
    position: 'absolute',
    textAlign: 'center',
    left: '34vw',
    top: '17vh',
    display: 'flex',
    flexDirection: 'column'
}
export const pot2 = {
    // borderRadius: '100px',
    position: 'absolute',
    textAlign: 'center',
    left: '66vw',
    top: '17vh',
    display: 'flex',
    flexDirection: 'column'
}
export const pot3 = {
    // borderRadius: '100px',
    position: 'absolute',
    textAlign: 'center',
    left: '75vw',
    top: '28.5vh',
    display: 'flex',
    flexDirection: 'column'
}
export const pot4 = {
    // borderRadius: '100px',
    position: 'absolute',
    textAlign: 'center',
    left: '77vw',
    top: '49vh',
    display: 'flex',
    flexDirection: 'column'
}
export const pot5 = {
    // borderRadius: '100px',
    position: 'absolute',
    textAlign: 'center',
    left: '71vw',
    top: '63.7vh',
    display: 'flex',
    flexDirection: 'column'
}
export const pot6 = {
    // borderRadius: '100px',
    position: 'absolute',
    textAlign: 'center',
    left: '54vw',
    top: '63.7vh',
    display: 'flex',
    flexDirection: 'column'
}
export const pot7 = {
    // borderRadius: '100px',
    position: 'absolute',
    textAlign: 'center',
    left: '35vw',
    top: '63.7vh',
    display: 'flex',
    flexDirection: 'column'
}
export const pot8 = {
    // borderRadius: '100px',
    position: 'absolute',
    textAlign: 'center',
    left: '19vw',
    top: '49vh',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 999999999999999999999999999999999
}

export const em_seat0 = {
    position: 'absolute',
    zIndex: '99999999',
    left: '13vw',
    top: '16vh'
}
export const em_seat1 = {
    position: 'absolute',
    zIndex: '99999999',
    left: '28vw',
    top: '1vh'
}
export const em_seat2 = {
    position: 'absolute',
    zIndex: '99999999',
    left: '65vw',
    top: '1vh'
}
export const em_seat3 = {
    position: 'absolute',
    zIndex: '99999999',
    left: '82vw',
    top: '16vh'
}
export const em_seat4 = {
    position: 'absolute',
    zIndex: '99999999',
    left: '86vw',
    top: '55vh'
}
export const em_seat5 = {
    position: 'absolute',
    zIndex: '99999999',
    left: '65vw',
    top: '79vh'
}
export const em_seat6 = {
    position: 'absolute',
    zIndex: '99999999',
    left: '47vw',
    top: '79vh'
}
export const em_seat7 = {
    position: 'absolute',
    zIndex: '99999999',
    left: '28vw',
    top: '79vh'
}
export const em_seat8 = {
    position: 'absolute',
    zIndex: '99999999',
    left: '10vw',
    top: '55vh'
}


export const card_points_0 = {
    x: -34,
    y: -20
}

export const card_points_1 = {
    x: -17,
    y: -30
}

export const card_points_2 = {
    x: 20,
    y: -32
}

export const card_points_3 = {
    x: 34,
    y: -20
}

export const card_points_4 = {
    x: 34,
    y: 20
}

export const card_points_5 = {
    x: 21,
    y: 34
}

export const card_points_6 = {
    x: 0,
    y: 34
}

export const card_points_7 = {
    x: 0,
    y: 34
}

export const card_points_8 = {
    x: -34,
    y: 19
}

export const pl_chips_end_0 = {
    x: 10.5,
    y: 7
}

export const pl_chips_end_1 = {
    x: 1.2,
    y: 14
}

export const pl_chips_end_2 = {
    x: 0,
    y: 14
}

export const pl_chips_end_3 = {
    x: -8.8,
    y: 7
}

export const pl_chips_end_4 = {
    x: -7,
    y: -3
}

export const pl_chips_end_5 = {
    x: 4,
    y: -10
}

export const pl_chips_end_6 = {
    x: 5.5,
    y: -9.5
}

export const pl_chips_end_7 = {
    x: 5.5,
    y: -9.5
}

export const pl_chips_end_8 = {
    x: 7.5,
    y: -9
}


// side pot to center points.
export const side_chips_end_0 = {
    x: 23,
    y: -16.7
}

export const side_chips_end_1 = {
    x: 14,
    y: -4.2
}

export const side_chips_end_2 = {
    x: -18.5,
    y: -4.2
}

export const side_chips_end_3 = {
    x: -27,
    y: -16
}

export const side_chips_end_4 = {
    x: -29,
    y: -36.1
}

export const side_chips_end_5 = {
    x: -23.5,
    y: -51
}

export const side_chips_end_6 = {
    x: -7,
    y: -51
}

export const side_chips_end_7 = {
    x: 12,
    y: -51
}

export const side_chips_end_8 = {
    x: 28.8,
    y: -36.5
}




export const pl_chips_win_0 = {
    x: -36,
    y: 0
}

export const pl_chips_win_1 = {
    x: -18,
    y: -13
}

export const pl_chips_win_2 = {
    x: 19,
    y: -13
}

export const pl_chips_win_3 = {
    x: 36,
    y: 2
}

export const pl_chips_win_4 = {
    x: 36,
    y: 38
}

export const pl_chips_win_5 = {
    x: 19,
    y: 52
}

export const pl_chips_win_6 = {
    x: 1,
    y: 52
}

export const pl_chips_win_7 = {
    x: -18,
    y: 52
}

export const pl_chips_win_8 = {
    x: -36,
    y: 38
}
