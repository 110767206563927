import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import * as clubAction from '../redux/actions/club-action';
import * as gameAction from '../redux/actions/game-action';
import * as loginAction from '../redux/actions/login-action';

import { bindActionCreators } from 'redux';

import '../assets/css/club.scss';
import '../assets/css/enginepoker.scss';
import MemberList from './MemberList';
import TableList from './TableList';
import { useCookies } from 'react-cookie';


function ClubDetails(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['se']);

    const [svuserid, setUserID] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [allGameType, setAllGameType] = useState([]);
    const [selClubName, setSelClubName] = useState(null);
    const [chips, setChipsCount] = useState(0);
    const [myChips, setMyChips] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [tableName, setTableName] = useState('');
    const [gameType, setGameType] = useState('');
    const [custom_game, setCustomGame] = useState(false);
    const [allClubsMembers, setAllClubsMembers] = useState([]);
    const [allClubsTables, setAllClubsTables] = useState([]);

    useEffect(() => {
        let userinfo = localStorage.getItem('user');
        let uEmail = localStorage.getItem('userEmail');
        if (!userinfo) {
            props.history.replace('/');
        } else {
            setUserID(userinfo);
            window.onclick = function (event) {
                if (event.target === document.getElementById("buyModal")) {
                    document.getElementById("buyModal").style.display = "none";
                }
                if (event.target === document.getElementById("tabModal")) {
                    document.getElementById("tabModal").style.display = "none";
                }
            }

            getMembersByClubID(props.match.params.name);
            getUserInfo(userinfo);

            props.gameAction.getAllGameType((res) => {
                if (res !== 'error' && res.status === 200) {
                    setAllGameType(res.data)
                }
            })

            console.log(userinfo);
            props.loginAction.getToken({ email: uEmail }, (response) => {
                setCookie('se', response.data, { path: '/' });
            })

        }
    }, [])

    const getUserInfo = (uid) => {
        props.clubAction.getUserInfo(uid, (res) => {
            if (res.data) {
                setUserInfo(res.data)
                setMyChips(res.data.chips)
            }
        })
    }

    const getMembersByClubID = (club_name) => {
        setSelClubName(club_name);
        let data = {
            club_name: club_name
        }
        props.clubAction.getMembersByClubID(data, (res) => {
            setAllClubsMembers(res.data)
        })
        props.clubAction.getTablesByClubID(data, (res) => {
            setAllClubsTables(res.data)
        })
    }

    const onPressEnterForTable = (e) => {
        if (e.key === 'Enter' && tableName && gameType) {
            createTable()
        }
    }

    const onPressEnterForBuyChips = (e) => {
        if (e.key === 'Enter' && chips) {
            buyChips()
        }
    }

    const approveClubMember = (userid) => {
        let data = {
            club_name: selClubName,
            firebase_id: userid
        }
        props.clubAction.approveClubMember(data, (res) => {
            getMembersByClubID(selClubName)
        })
    }

    const resetTextFields = () => {
        setChipsCount(0);
        setTableName('');
        setGameType('');
        setCustomGame(false);
    }

    const buyChips = () => {
        let data = {
            chips: chips,
            firebase_id: userInfo.firebase_id
        }
        props.clubAction.buyChips(data, (res) => {
            resetTextFields();
            document.getElementById("buyModal").style.display = "none";
            getUserInfo(svuserid)
        })
    }

    const createTable = () => {
        let data = {
            created_by: svuserid,
            table_name: tableName,
            default_game_type: gameType,
            club_name: selClubName,
            custom_game_allowed: custom_game,
            created_by_screen_name: userInfo.screen_name
        }
        props.gameAction.createTable(data, () => {
            resetTextFields();
            document.getElementById("tabModal").style.display = "none";
            getMembersByClubID(props.match.params.name);
        })
    }

    const showTab = (tabindex) => {
        setActiveTab(tabindex);
    }

    return (
        <main id="club">
            <div className="page active">
                <div className="fixed-header">
                    <div className="headerName">
                        <span className="logo1">Club Details</span>
                    </div>
                    <div className="chipsIndicator">
                        <div className="icon"></div>
                        <div className="value">{myChips}</div>
                    </div>
                    <div className="top-menu">
                        <a href="javascript:void(0)" onClick={() => props.history.replace('/club')} className="btnItem btnBack icon-back" data-action="page" data-item="#home">
                            <div className="btnItemBackground"></div>
                            <div className="btnItemIcon"></div>
                            <div className="btnItemText">Back</div>
                        </a>
                        <a href="javascript:void(0)" onClick={() => {
                            document.getElementById("tabModal").style.display = "block";
                        }} className="btnItem btnBack icon-clubs" data-action="page" data-item="#home">
                            <div className="btnItemBackground"></div>
                            <div className="btnItemIcon"></div>
                            <div className="btnItemText">Create Table</div>
                        </a>
                        <a href="javascript:void(0)" onClick={() => {
                            document.getElementById("buyModal").style.display = "block";
                        }} className="btnItem btnBack icon-casino" data-action="page" data-item="#home">
                            <div className="btnItemBackground"></div>
                            <div className="btnItemIcon"></div>
                            <div className="btnItemText">Buy Chips</div>
                        </a>
                    </div>
                    <div className="tab-menu">
                        <a href="javascript:void(0)"><div className="profile-name">{userInfo && userInfo.screen_name}</div></a>
                        <div className="tab-row row0">
                            <a onClick={() => showTab(0)} href="javascript:void(0)" className={activeTab == 0 ? "btnTab active" : "btnTab"} data-game="texas" data-group="0">MEMBERS</a>
                            <a onClick={() => showTab(1)} href="javascript:void(0)" className={activeTab == 1 ? "btnTab active" : "btnTab"} data-game="texas" data-group="1">TABLES</a>
                        </div>
                    </div>
                </div>
                <div className="fixed-body">
                    <div className="games lobby cashgame">
                        {activeTab === 0 && allClubsMembers && allClubsMembers.map((m, i) =>
                            <MemberList {...props} approveClubMember={approveClubMember} data={m} />)}

                        {activeTab === 1 && allClubsTables && allClubsTables.map((m, i) =>
                            <TableList userInfo={userInfo} {...props} data={m} />)}

                        {/* {activeTab === 0 && allClubs && allClubs.map((c, i) => <ClubList {...props} data={c} />)}
                        {activeTab === 1 && joinedClubs && joinedClubs.map((c, i) => <ClubList {...props} data={c} />)} */}
                    </div>
                </div>
            </div>

            <div id="buyModal" className="modal">
                <div className="modal-content" id="clubModal">
                    <span onClick={() => {
                        document.getElementById("buyModal").style.display = "none";
                    }} className="close">&times;</span>
                    <center><h3>{`BUY CHIPS`}</h3></center>
                    <div className="form">
                        <p style={{ color: '#fff' }}>Total Chips: {myChips}</p>
                        <div className="formControl">
                            <label>Enter Chips Count</label>
                            <input onKeyDown={onPressEnterForBuyChips} value={chips} onChange={(e) => setChipsCount(e.target.value)} type="text" />
                        </div>
                        <div className="lastPart">
                            <button onClick={() => buyChips()}>Buy</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="tabModal" className="modal">
                <div className="modal-content" id="clubModal">
                    <span onClick={() => {
                        document.getElementById("tabModal").style.display = "none";
                    }} className="close">&times;</span>
                    <center><h3>{`CREATE TABLE`}</h3></center>
                    <div className="form">
                        <div className="formControl">
                            <label>Enter Table Name</label>
                            <input onKeyDown={onPressEnterForTable} value={tableName} onChange={(e) => setTableName(e.target.value)} type="text" />
                        </div>
                        <div className="formControl">
                            <label>Select Default Game Type</label>
                            <select onKeyDown={onPressEnterForTable} value={gameType} onChange={(e) => setGameType(e.target.value)}>
                                <option value={''}>{`---Select---`}</option>
                                {allGameType.map((g, i) => <option value={g.id}>{g.name}</option>)}
                            </select>
                        </div>
                        <div className="formControl">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <span>Custom Game:</span>
                                <input
                                    onKeyDown={onPressEnterForTable}
                                    type="checkbox"
                                    value={custom_game}
                                    onChange={(e) => setCustomGame(e.target.checked)}
                                    style={{ margin: '5px 0px 25px -5px', width: '50px' }}
                                />
                            </div>
                        </div>
                        <div className="lastPart">
                            <button onClick={() => createTable()}>Create Table</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = dispatch => {
    return {
        loginAction: bindActionCreators(loginAction, dispatch),
        gameAction: bindActionCreators(gameAction, dispatch),
        clubAction: bindActionCreators(clubAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubDetails);