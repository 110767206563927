import firebase from "firebase";
import 'firebase/firestore';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBo8-4WL1KaAqFHYWoVc373kxEhCSEZnlg",
  authDomain: "zpoker-b600b.firebaseapp.com",
  databaseURL: "https://zpoker-b600b.firebaseio.com",
  projectId: "zpoker-b600b",
  storageBucket: "zpoker-b600b.appspot.com",
  messagingSenderId: "747585397283",
  appId: "1:747585397283:web:48b72d3a308209fe690796"
});

const db = firebaseApp.firestore();

export { db };