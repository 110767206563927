
import * as nineplayer from '../assets/css/nineplayer';
import { PlaySoundFromAssets } from './PlaySound';

import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

var bet = new Audio(require('../assets/sound/bet.wav'));

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); return true });
    return images;
}

const images = importAll(require.context('../assets/cards/png', true, /\.(png|jpe?g|svg)$/));
const imagesAll = importAll(require.context('../assets', true, /\.(png|jpe?g|svg)$/));

var cards = []
var card_value = [
    'AH', '2H', '3H', '4H', '5H', '6H', '7H', '8H', '9H', 'TH', 'JH', 'QH', 'KH',
    'AD', '2D', '3D', '4D', '5D', '6D', '7D', '8D', '9D', 'TD', 'JD', 'QD', 'KD',
    'AS', '2S', '3S', '4S', '5S', '6S', '7S', '8S', '9S', 'TS', 'JS', 'QS', 'KS',
    'AC', '2C', '3C', '4C', '5C', '6C', '7C', '8C', '9C', 'TC', 'JC', 'QC', 'KC'
]

export async function Resetcards() {
    var feltDiv = document.getElementById('felt');
    if (feltDiv) {
        document.getElementById('ontable').removeChild(feltDiv)
    }
    var felt2Div = document.getElementById('felt2');
    if (felt2Div) {
        document.getElementById('ontable').removeChild(felt2Div)
    }

    document.querySelectorAll('.dis_card').forEach((c) => {
        c.remove();
    })


    var d_t = document.querySelectorAll('td');
    if (d_t) {
        d_t.forEach((tcard) => {
            if (tcard.hasChildNodes()) {
                tcard.innerHTML = ``
            }
        })
    }
}

export async function AnimateAfterCardChange(playerCards, current_player, before_change_pl_cards, onCardSelect) {
    var betControl = document.getElementById('ControlBar');
    var ccDiv = document.getElementById('AutoControls_CC');
    if (ccDiv) {
        betControl.removeChild(ccDiv);
    }
    let c_i = 0;

    document.querySelectorAll('.dis_card').forEach(async (ac) => {
        let card_pre = playerCards.some((cp) => cp.card === ac.id);
        if (card_pre) {
            c_i += 1;
            let index = before_change_pl_cards.findIndex((pl) => pl.card === ac.id);
            let changed_card = playerCards[index];
            let coords = { x: 0, y: 0 };
            let d_card = await getDecyptedCard(changed_card.card);
            let cindex = cards.findIndex((c) => c.className == d_card);
            setTimeout(() => {
                new window.TWEEN.Tween(coords)
                    .to(nineplayer['card_points_' + changed_card.seat_no], 100) // Move to (300, 200) in 1 second.
                    .easing(window.TWEEN.Easing.Quadratic.Out)
                    .onUpdate(function () {
                        cards[cindex].style.setProperty('transform', 'translate(' + coords.x + 'vw, ' + coords.y + 'vh)');
                    }).onComplete(function () {
                        PlaySoundFromAssets('table_cards');

                        ac.style.transform = 'none';
                        ac.style.border = 'none';
                        ac.className = `dis_card`;

                        if (current_player == changed_card.player_id) {
                            ac.id = changed_card.card;
                            ac.firstChild.src = images[d_card + '.png'];
                        }
                        if (cards[cindex]) {
                            cards[cindex].remove();
                        }
                    }).start();
                requestAnimationFrame(animate2);
            }, c_i * 100);
        }
    })
}

export async function initializeDeck() {
    var c1 = document.getElementById('ontable');
    var felt = document.createElement("div");
    felt.id = "felt";
    c1.appendChild(felt);
    var card = document.createElement("div");
    card.innerHTML = "<img src=" + images['cardback3.png'] + ">";
    for (var i = 0; i < card_value.length; i++) {
        var newCard = card.cloneNode(true);
        newCard.className = card_value[i];
        newCard.classList.remove("rotate-vert-center");
        newCard.style.visibility = 'hidden';

        newCard.style.top = "37%";
        newCard.style.left = "48%";

        felt.appendChild(newCard);
        cards.push(newCard);
    }
}


function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function getDecyptedCard(card) {
    try {
        let sec = getCookie('se');
        let bytes = AES.decrypt(card, sec);
        console.log(bytes)
        let originalText = bytes.toString(Utf8);
        return originalText;
    } catch(e) {
        return ''
    }
    
}

export async function ArrangeDeck(playerCards, current_player, withAnimation, onCardSelect, arrang_line) {
    if (playerCards) {

        let card_in = 0
        let card_arra = playerCards.sort((a, b) => a.card_order - b.card_order)
        if (withAnimation) {
            for (let i = 0; i < card_arra.length; i++) {
                let d_card = await getDecyptedCard(card_arra[i].card);
                let index = cards.findIndex((c) => c.className == d_card);
                console.log(d_card, index)
                // if(index > 0) {
                    cards[i].style.visibility = 'visible';
                // }
                var coords = { x: 0, y: 0 };
                setTimeout(() => {
                    new window.TWEEN.Tween(coords)
                        .to(nineplayer['card_points_' + card_arra[i].seat_no], 50)
                        .easing(window.TWEEN.Easing.Quadratic.Out)
                        .onUpdate(function () {
                            cards[i].style.setProperty('transform', 'translate(' + coords.x + 'vw, ' + coords.y + 'vh)');
                        }).onComplete(function () {
                            PlaySoundFromAssets('table_cards');
                            var cln = cards[i].cloneNode(true);
                            cln.style.transform = 'none';
                            cln.className = "dis_card";
                            if (current_player == card_arra[i].player_id) {
                                cln.id = card_arra[i].card;
                                cln.onclick = () => onCardSelect(card_arra[i].card)
                                cln.firstChild.src = images[d_card + '.png'];
                            }
                            document.getElementById(`plc${card_arra[i].seat_no}`).appendChild(cln);
                            if (cards[i]) {
                                cards[i].remove();
                            }
                        })
                        .start();
                    requestAnimationFrame(animate2);
                }, i * 50)
            }
            requestAnimationFrame(animate2);
        } else {
            for (let i = 0; i < card_arra.length; i++) {
                let d_card = await getDecyptedCard(card_arra[i].card);
                let index = cards.findIndex((c) => c.className == d_card);
                console.log(d_card, index)
                var coords = nineplayer['card_points_' + card_arra[i].seat_no];

                new window.TWEEN.Tween(coords)
                    .to({ x: 0, y: 0 }, 50)
                    .easing(window.TWEEN.Easing.Quadratic.Out)
                    .onUpdate(function () {
                        // cards[index].style.setProperty('transform', 'translate(' + coords.x + 'vw, ' + coords.y + 'vh)');
                    }).onComplete(function () {
                        console.log(index, i)
                        var cln = cards[i].cloneNode(true);
                        cln.className = 'dis_card';
                        cln.style.transform = 'none';
                        cln.style.visibility = 'visible';
                        console.log(cln)
                        if (current_player == card_arra[i].player_id) {
                            cln.id = card_arra[i].card;
                            cln.onclick = () => onCardSelect(card_arra[i].card)
                            cln.firstChild.src = images[d_card + '.png'];
                        }

                        if (document.getElementById(`plc${card_arra[i].seat_no}`)) {
                            document.getElementById(`plc${card_arra[i].seat_no}`).appendChild(cln);
                        }
                        if (cards[i]) {
                            cards[i].remove();
                        }
                    })
                    .start();
                requestAnimationFrame(animate2);
            }
        }
    }
}

export async function AnimateAndDistributeCoins(index, chips, name, player_info) {
    var c1 = document.getElementById('ontable');
    if (c1) {
        setTimeout(() => {
            var felt = document.createElement("div");
            felt.id = "felt2";
            felt.style.position = 'absolute';
            felt.style.zIndex = '9999999999999999999999999999999999999';
            c1.appendChild(felt);

            var card1 = document.createElement("div");
            // card1.id = "card1";
            card1.innerHTML = `<img src=${imagesAll['coin-stack.png']} height="55" /><span class="wincoinval">${chips}</span>`
            // card1.style.display = 'flex';
            // card1.style.flexDirection = 'row';
            card1.style.top = '18.3vh';
            card1.style.left = '47vw';
            felt.appendChild(card1);
            // c1.appendChild(card1);

            let pl = player_info.filter(p => p.name === name);
            requestAnimationFrame(animate);
            var coords = { x: 0, y: 0 }; // Start at (0, 0)
            PlaySoundFromAssets('collect_coin');
            var tween = new window.TWEEN.Tween(coords) // Create a new tween that modifies 'coords'.
                .to(nineplayer['pl_chips_win_' + pl[0].seat_no], 1000) // Move to (300, 200) in 1 second.
                .easing(window.TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
                .onUpdate(function () { // Called after tween.js updates 'coords'.
                    felt.style.setProperty('transform', 'translate(' + coords.x + 'vw, ' + coords.y + 'vh)');
                    // collectCard.play();
                })
                .onComplete(function () {
                    if (felt && window.$(c1).has(felt).length) {
                        c1.removeChild(felt);
                    }
                })
                .start(); // Start the tween immediately.
        }, 1000)

    }
}

function animate(time) {
    requestAnimationFrame(animate);
    window.TWEEN.update(time);
}

function animate1(time) {
    requestAnimationFrame(animate1);
    window.TWEEN.update(time);
}

function animate2(time) {
    requestAnimationFrame(animate2);
    window.TWEEN.update(time);
}

export async function animateChipsFromPlayerHandToBet(animate_info) {
    var c1 = document.getElementById('ontable');
    var cr1 = document.getElementById('card1');

    if (!cr1) {
        bet.play();
        var card1 = document.createElement("div");
        card1.id = "card1";
        card1.innerHTML = `<img src=${imagesAll['coin-red.png']} style="height: 3vh" /><div style="font-weight: bolder; color: #fff; font-size: 2vh;">${animate_info.chips}</div>`;
        card1.style.position = 'absolute';
        card1.style.display = 'flex';
        card1.style.flexDirection = 'row';
        card1.style.top = nineplayer['seat' + animate_info.seat_no].top ? Number(nineplayer['seat' + animate_info.seat_no].top.replace('vh', '')) + 6 + 'vh' : "";
        card1.style.left = nineplayer['seat' + animate_info.seat_no].left ? Number(nineplayer['seat' + animate_info.seat_no].left.replace('vw', '')) + 2 + 'vw' : "";
        card1.style.zIndex = '9999999999999999999999999999999999999';
        c1.appendChild(card1);

        requestAnimationFrame(animate);


        var coords = { x: 0, y: 0 }; // Start at (0, 0)
        var tween = new window.TWEEN.Tween(coords) // Create a new tween that modifies 'coords'.
            .to(nineplayer['pl_chips_end_' + animate_info.seat_no], 200) // Move to (300, 200) in 1 second.
            .easing(window.TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            .onUpdate(function () { // Called after tween.js updates 'coords'.
                // Move 'box' to the position described by 'coords' with a CSS translation.
                card1.style.setProperty('transform', 'translate(' + coords.x + 'vw, ' + coords.y + 'vh)');
            })
            .onComplete(function () {
                if (card1) {
                    c1.removeChild(card1);
                }
            })
            .start(); // Start the tween immediately.
    }

}

export async function animateSidePotToCenter() {
    if (document.querySelectorAll('#pl_chips_animate') && document.querySelectorAll('#pl_chips_animate').length > 0) {
        PlaySoundFromAssets('collect_coin');
    }
    document.querySelectorAll('#pl_chips_animate').forEach((div_side) => {
        let seat_no = div_side.className;
        var ava_div = document.getElementsByClassName("cln_" + seat_no);
        if (ava_div.length === 0) {
            var cln = div_side.cloneNode(true);
            document.getElementById(seat_no).appendChild(cln);
            cln.classList.add("cln_" + seat_no);
            var coords = { x: 0, y: 0 };
            new window.TWEEN.Tween(coords).to(nineplayer['side_chips_end_' + seat_no], 700)
                .easing(window.TWEEN.Easing.Quadratic.Out)
                .onUpdate(function () {
                    cln.style.setProperty('transform', 'translate(' + coords.x + 'vw, ' + coords.y + 'vh)');
                    div_side.style.setProperty('transform', 'translate(' + coords.x + 'vw, ' + coords.y + 'vh)');
                    // collectCard.play();
                }).onComplete(function () {
                    if (cln) {
                        cln.remove();
                    }
                })
                .start();
            requestAnimationFrame(animate1);
        }
    })
    requestAnimationFrame(animate1);
}

export async function showCards(playerCards) {
    // document.querySelectorAll('.dis_card').forEach((c) => {
    //     c.firstChild.src = images[c.classList[0] + '.png'];
    // })
    document.querySelectorAll('.dis_card').forEach((c) => {
        c.remove();
    })
    let card_arra = playerCards.sort((a, b) => a.card_order - b.card_order);

    for (let i = 0; i < card_arra.length; i++) {
        let index = cards.findIndex((c) => c.className == card_arra[i].card);
        var coords = nineplayer['card_points_' + card_arra[i].seat_no];
        console.log("-indexindexindexindexindex=-=> ", index , coords)
        new window.TWEEN.Tween(coords)
            .to({ x: 0, y: 0 }, 50)
            .easing(window.TWEEN.Easing.Quadratic.Out)
            .onUpdate(function () {
                // cards[index].style.setProperty('transform', 'translate(' + coords.x + 'vw, ' + coords.y + 'vh)');
            }).onComplete(function () {
                console.log(index, i)
                var cln = cards[i].cloneNode(true);
                cln.className = 'dis_card';
                cln.style.transform = 'none';
                cln.style.visibility = 'visible';
                console.log(cln)
                // if (current_player == card_arra[i].player_id) {
                    cln.id = card_arra[i].card;
                    // cln.onclick = () => onCardSelect(card_arra[i].card)
                    cln.firstChild.src = images[card_arra[i].card + '.png'];
                // }

                if (document.getElementById(`plc${card_arra[i].seat_no}`)) {
                    document.getElementById(`plc${card_arra[i].seat_no}`).appendChild(cln);
                }
                if (cards[i]) {
                    cards[i].remove();
                }
            })
            .start();
        requestAnimationFrame(animate2);
    }
}

// Need to change this animation to tween.

export async function showTableCards(table_cards) {
    if (table_cards) {
        table_cards = table_cards.sort((a, b) => a.order - b.order);
        let count = 0
        table_cards.map((tc, i) => {
            console.log("==> ", tc)
            if (document.getElementById(`td_${tc.position.row}_${tc.position.column}`)) {
                let checkChildNode = window.$(`#td_${tc.position.row}_${tc.position.column}`).children('div.em_div').length;
                if (checkChildNode > 0) {
                    count += 200;

                    return new Promise((resolve) => setTimeout(() => {
                        PlaySoundFromAssets('player_cards');
                        document.getElementById(`td_${tc.position.row}_${tc.position.column}`).innerHTML =
                            `<div style="width: inherit; height: inherit;"> 
                                            <img style="
                                                transform: rotate(${tc.position.angle}deg);
                                                width: 100%;
                                                margin-left: ${tc.position.marginLeft ? tc.position.marginLeft : 0}px;
                                                margin-right: ${tc.position.marginRight ? tc.position.marginRight : 0}px;
                                                margin-top: ${tc.position.marginTop ? tc.position.marginTop : 0}px;
                                                margin-bottom: ${tc.position.marginBottom ? tc.position.marginBottom : 0}px;
                                            "
                                            src=${images[tc.card + '.png']} />
                                        </div>`
                        resolve();
                    }, count))
                }

                if (checkChildNode === 0) {
                    return new Promise((resolve) => setTimeout(() => {
                        console.log(count)
                        document.getElementById(`td_${tc.position.row}_${tc.position.column}`).innerHTML =
                            `<div style="width: inherit; height: inherit;"> 
                                                <img style="
                                                    transform: rotate(${tc.position.angle}deg);
                                                    width: 100%;
                                                    margin-left: ${tc.position.marginLeft ? tc.position.marginLeft : 0}px;
                                                    margin-right: ${tc.position.marginRight ? tc.position.marginRight : 0}px;
                                                    margin-top: ${tc.position.marginTop ? tc.position.marginTop : 0}px;
                                                    margin-bottom: ${tc.position.marginBottom ? tc.position.marginBottom : 0}px;
                                                "
                                                src=${images[tc.card + '.png']} />
                                            </div>`
                        resolve();
                    }, 200))
                }


            }
        })
        // table_cards = table_cards.sort((a, b) => a.order - b.order)
        // let index = 0
        // for (let tc in table_cards) {
        //     if (document.getElementById(`td_${table_cards[tc].position.row}_${table_cards[tc].position.column}`)) {
        //         // let checkChildNode = document.getElementById(`td_${table_cards[tc].position.row}_${table_cards[tc].position.column}`).hasChildNodes();
        //         // if (!checkChildNode) {
        //         index = index + 1;
        //         (function (tab_card, index) {
        //             setTimeout(() => {
        //                 PlaySoundFromAssets('table_cards');
        //                 if (document.getElementById(`td_${tab_card.position.row}_${tab_card.position.column}`)) {
        //                     document.getElementById(`td_${tab_card.position.row}_${tab_card.position.column}`).innerHTML =
        //                         `<div style="width: inherit; height: inherit;"> 
        //                             <img style="
        //                                 transform: rotate(${tab_card.position.angle}deg);
        //                                 width: 100%;
        //                                 margin-left: ${tab_card.position.marginLeft ? tab_card.position.marginLeft : 0}px;
        //                                 margin-right: ${tab_card.position.marginRight ? tab_card.position.marginRight : 0}px;
        //                                 margin-top: ${tab_card.position.marginTop ? tab_card.position.marginTop : 0}px;
        //                                 margin-bottom: ${tab_card.position.marginBottom ? tab_card.position.marginBottom : 0}px;
        //                             "
        //                             src=${images[tab_card.card + '.png']} />
        //                         </div>`
        //                 }
        //             }, index * 200)
        //         })(table_cards[tc], index);
        //         // }
        //     }
        // }
    }
}

export async function showDummyTableCards(table_cards) {
    if (table_cards) {
        table_cards.map((tab_card) => {
            if (!tab_card.card_change) {
                let dum_div = document.createElement('div');
                dum_div.className = 'em_div'
                dum_div.style.width = 'inherit';
                dum_div.style.height = 'inherit';
                // dum_div.style.position = 'absolute';
                dum_div.innerHTML = `<img style=" transform: rotate(${tab_card.angle}deg);
                                    width: 100%;
                                    margin-left: ${tab_card.marginLeft || 0}px;
                                    margin-right: ${tab_card.marginRight || 0}px;
                                    margin-top: ${tab_card.marginTop || 0}px;
                                    margin-bottom: ${tab_card.marginBottom || 0}px;" 
                                    src=${images['cardoutline.png']} />`;
                if (window.$(`#td_${tab_card.row}_${tab_card.column}`).is(':empty')) {
                    document.getElementById(`td_${tab_card.row}_${tab_card.column}`).appendChild(dum_div)
                }
            }
            if (tab_card.card_change) {
                let dum_div = document.createElement('div');
                dum_div.style.height = 'inherit';
                dum_div.style.justifyContent = 'center';
                dum_div.style.alignItems = 'center';
                dum_div.style.display = 'flex';
                dum_div.innerHTML = "<img style='height: 3vh' src=" + images['cardback3.png'] + ">";
                // dum_div.innerHTML = `<i style="font-size: 2.5vw" class="fa fa-refresh" aria-hidden="true"></i>`;
                let count_div = document.createElement('div');
                count_div.style.position = 'absolute';
                count_div.style.justifyContent = 'center';
                count_div.style.alignItems = 'center';
                count_div.style.display = 'flex';
                count_div.style.fontSize = '1.1vw';
                count_div.style.color = '#fff';
                count_div.style.fontWeight = 'bold';
                count_div.innerHTML = tab_card.count;
                dum_div.appendChild(count_div)
                if (window.$(`#td_${tab_card.row}_${tab_card.column}`).is(':empty')) {
                    document.getElementById(`td_${tab_card.row}_${tab_card.column}`).appendChild(dum_div)
                }
            }
        })
    }
}