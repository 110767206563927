import React, { useLayoutEffect, useState, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import * as sixPlayer from '../assets/css/sixplayer';
import * as ninePlayer from '../assets/css/nineplayer';
import * as clubAction from '../redux/actions/club-action';
import { card_val } from '../config/CardValue';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function ShowAvatarWithStatus(props) {
    const { hand_info, p } = props
    if (p.status === 'sitout') {
        return (<div className="show_status"> {"Sitout"} </div>)
    }
    if (hand_info && hand_info.players && hand_info.players[p.name] && hand_info.players[p.name].fold_status) {
        return (<div className="show_status"> {"folded"} </div>)
    }
    if (hand_info && hand_info.players && hand_info.players[p.name] && hand_info.players[p.name].all_in_status) {
        return (
            <>
                <div className="show_status"> {"AllIn"} </div>
            </>
        )
    }
    if (hand_info && hand_info.last_hand_action && hand_info.last_hand_action.player_name === p.name) {
        return (<div className="show_hide show_status"> {hand_info.last_hand_action.action} </div>)
    }

    return (
        <></>
    )
}

function TablePlayer(props) {

    const {
        hand_info, player_info, onPressSitIn, userinfo, club_name, table_id
    } = props;

    useEffect(() => {
        window.onclick = function (event) {
            if (event.target == document.getElementById("buyModal")) {
                document.getElementById("buyModal").style.display = "none";
            }
        }
    }, [])

    let currentCSS = ninePlayer;

    let arrayD = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const [width, height] = useWindowSize();

    let av_height = height / (13)

    let duration = 0;

    if (hand_info && hand_info.arrange_cards_metadata && hand_info.arrange_cards_metadata.start_time) {
        duration = (hand_info.arrange_cards_metadata.start_time + hand_info.arrange_cards_metadata.time_limit - Math.floor(Date.now())) / 1000
    }

    if (hand_info && hand_info.card_change_metadata && hand_info.card_change_metadata.start_time) {
        duration = (hand_info.card_change_metadata.start_time + hand_info.card_change_metadata.time_limit - Math.floor(Date.now())) / 1000
    }

    if (hand_info && hand_info.action_metadata && hand_info.action_metadata.start_time) {
        duration = (hand_info.action_metadata.start_time + 16000 - Math.floor(Date.now())) / 1000
    }

    const getTotalTopImage = (count) => {
        const inputs = [];
        for (let i = 0; i < count; i++) {
            inputs.push(
                <img src={require('../assets/cards/png/cardback3.png')} />
            )
        }
        return inputs;
    }

    const getTotalBackImage = (count) => {
        const inputs = [];
        for (let i = 0; i < count; i++) {
            inputs.push(
                <img src={require('../assets/cards/png/cardback3.png')} />
            )
        }

        return inputs;
    }

    return (
        <div>
            {player_info && player_info.map((p, i) => <>
                <div id={`seat${p.seat_no}`} className={p.name === (userinfo && userinfo.screen_name) ? "my_card_div" : ""} key={`seat${p.seat_no}`} style={{ ...currentCSS[`seat` + p.seat_no] }}>
                    {hand_info && hand_info.arrangement_type && hand_info.arrangement_type.length > 0 && p.name === (userinfo && userinfo.screen_name) && <div className="arrange_div_card" style={{ zIndex: -1 }}>
                        {hand_info.arrangement_type.map((ar) =>
                            <div id="card_border">
                                <div className="al_card_top">
                                    {getTotalTopImage(ar)}
                                </div>
                                {getTotalBackImage(ar)}
                            </div>
                        )}
                    </div>}
                    <div id={`plc${p.seat_no}`} className={hand_info && hand_info.arrangement_type && hand_info.arrangement_type.length > 0 && p.name === (userinfo && userinfo.screen_name) ? 'card-container my_cards card_arr sortable' : p.name === (userinfo && userinfo.screen_name) ? "card-container my_cards sortable" : "card-container sortable"}>

                    </div>
                    <div className="player-container">
                        <div className="pl-ava-container">
                            {((hand_info && hand_info.action_metadata && hand_info.action_metadata.action_to === p.name) ||
                                (hand_info && hand_info.arrange_cards_metadata && hand_info.arrange_cards_metadata.start_time) ||
                                (hand_info && hand_info.card_change_metadata && hand_info.card_change_metadata.start_time)) ?
                                <>
                                    <CountdownCircleTimer
                                        isPlaying
                                        initialRemainingTime={duration < 0 ? 0 : duration}
                                        duration={hand_info.action_metadata ? (hand_info.action_metadata.time_limit / 1000) : hand_info.card_change_metadata ? (hand_info.card_change_metadata.time_limit / 1000) : hand_info.arrange_cards_metadata ? (hand_info.arrange_cards_metadata.time_limit / 1000) : 0}
                                        colors={[
                                            ['#36FF33', 0.25],
                                            ['#36FF33', 0.25],
                                            ['#FF333F', 0.25],
                                            ['#FF333F', 0.25],
                                        ]}
                                        size={av_height}
                                        strokeWidth={5}
                                        isLinearGradient={false}
                                        trailColor={`transparent`}
                                    >
                                        {({ remainingTime }) => {
                                            return (<>
                                                {remainingTime === (hand_info.action_metadata ? (hand_info.action_metadata.time_limit / 2000) : hand_info.card_change_metadata ? (hand_info.card_change_metadata.time_limit / 2000) : hand_info.arrange_cards_metadata ? (hand_info.arrange_cards_metadata.time_limit / 2000) : 0) &&
                                                    <div style={{ visibility: 'hidden', position: 'absolute' }}>
                                                        <audio autoPlay controls>
                                                            <source src={require('../assets/sound/sfx_time.mp3')} type="audio/mpeg" />
                                                        </audio>
                                                    </div>
                                                }
                                            </>)
                                        }}
                                    </CountdownCircleTimer>
                                    <img style={{ position: 'absolute' }} className="pl-ava rotate-vert-center" src={require('../assets/avatar10.jpg')} />
                                </> :
                                <img className="pl-ava-no-timer" src={require('../assets/avatar10.jpg')} />}
                        </div>
                        <div className="pl-name-chips">
                            <ShowAvatarWithStatus hand_info={hand_info} p={p} />
                            <div className="player-name"> {p.name}
                                {hand_info && hand_info.max_card_change && hand_info.current_step !== 'card_change' && hand_info.players[userinfo.screen_name] && <div class="card_change_info">
                                    <img src={require('../assets/cards/png/cardback3.png')} />
                                    <span>{hand_info.players[userinfo.screen_name].card_changed_count || 0}/{hand_info.max_card_change}</span>
                                </div>}
                            </div>
                            <div className="chips"> {p.chips} </div>
                        </div>
                    </div>
                </div>
                {hand_info && p.name === (userinfo && userinfo.screen_name) ?
                    hand_info.players[userinfo.screen_name] && hand_info.players[userinfo.screen_name].best_hand && hand_info.players[userinfo.screen_name].best_hand.length > 0 &&
                    <div id="best_hand_tab" style={{ position: 'fixed', bottom: '60px', right: '10px', color: 'white', zIndex: 99 }}>
                        <table className="b_hand">
                            {hand_info.players[userinfo.screen_name].best_hand.map((bh, i) =>
                                <tr id="td_dis" key={`bhx${i}`} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <td id="custom_board">{bh.board}</td>
                                    <td>{bh.type}</td>
                                    <td className="hand_name">{bh.handName && bh.handName.includes('three') ? bh.handName.replace(/three/gi, "3") : bh.handName} </td>
                                    <td id="custom_card_block"> {bh.hand_cards && bh.hand_cards.sort((a, b) => card_val[a.slice(0, -1)] - card_val[b.slice(0, -1)]).map((c) => {
                                        if (c.substring(c.length - 1).toLowerCase() === 'h') {
                                            return <div style={{ color: 'red' }} id="custom_mini_card">
                                                <span>{c.slice(0, -1)}</span>
                                                <span>♥</span>
                                            </div>
                                        }
                                        if (c.substring(c.length - 1).toLowerCase() === 's') {
                                            return <div style={{ color: 'black' }} id="custom_mini_card">
                                                <span>{c.slice(0, -1)}</span>
                                                <span>♠</span>
                                            </div>
                                        }
                                        if (c.substring(c.length - 1).toLowerCase() === 'c') {
                                            return <div style={{ color: 'green' }} id="custom_mini_card">
                                                <span>{c.slice(0, -1)}</span>
                                                <span>♣</span>
                                            </div>
                                        }
                                        if (c.substring(c.length - 1).toLowerCase() === 'd') {
                                            return <div style={{ color: 'blue' }} id="custom_mini_card">
                                                <span>{c.slice(0, -1)}</span>
                                                <span>♦</span>
                                            </div>
                                        }
                                    })}</td>
                                    {/* {bh.hand_cards ? <div>{bh.hand_cards.toString()}</div> : <div></div>} */}
                                </tr>
                            )}
                        </table>
                    </div> : null}
            </>)}
            {
                userinfo && player_info && player_info.filter(d1 => d1.name === userinfo.screen_name).length == 0 && arrayD.map((d, i) => {
                    let post = `em_seat` + i
                    if (player_info.filter(d1 => d1.seat_no === i).length === 0) {
                        return <div key={`seatp${i}`} style={currentCSS[post]}>
                            <img onClick={() => onPressSitIn(i, userinfo.screen_name)} style={{ position: 'absolute', cursor: 'pointer', height: '8vh', width: '8vh' }} src={require('../assets/seat.png')} />
                        </div>
                    }
                    return null
                })
            }
        </div >
    )
}

const TablePlayerMemo = React.memo(TablePlayer);
export default TablePlayerMemo;

// const mapDispatchToProps = dispatch => {
//     return {
//         clubAction: bindActionCreators(clubAction, dispatch)
//     }
// }

// export default connect(null, mapDispatchToProps)();