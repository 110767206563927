import React, { useState, useEffect } from 'react';
import OnTable from './OnTable';

function GameTableHome(props) {
    const [completeView, setCompleteView] = useState(true);

    useEffect(() => {
        window.$(function () {
            window.$('#toggle-trigger').change(function () {
                setCompleteView(window.$(this).prop('checked'));
            })
        });

        const urlParams = new URLSearchParams(window.location.search);
        // clubName = urlParams.get('club_name');
        // tableId = urlParams.get('table_id');

    }, [])

    return (
        <>
            {/* <div style={{ position: 'absolute', zIndex: '99999999', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <span style={{ color: '#fff', fontWeight: 'bolder', marginRight: '10px' }}> My View: </span>
                <input id="toggle-trigger" className="toggle" data-style="ios" checked={completeView} type="checkbox" data-toggle="toggle" />
            </div> */}
            {completeView ? <OnTable {...props} /> : <div></div>}
        </>
    )
}

export default GameTableHome;
