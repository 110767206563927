// import { SET_FILTER } from "../actionTypes";

function gameReducer(state = null, action) {
    switch (action.type) {
        case 'SET_TABLE_LIST': {
            return {
                ...state,
                table_list: action.payload
            }
        }
        case 'SET_TABLE_PLAYERS': {
            return {
                ...state,
                table_player: action.payload,
                table_id: action.table_id,
                table_name: action.table_name
            }
        }
        case 'SET_HAND_INFO': {
            return {
                ...state,
                hand_info: action.payload,
                hand_id: action.hand_id,
                table_info: action.table_info
            }
        }
        default: {
            return state;
        }
    }
};

export default gameReducer;