import 'firebase/firestore';
import Axios from "axios";
import * as CONSTANT from '../../config/constant';

export const register = (data, callback) => {
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "register",
            method: 'POST',
            data: data
        }).then(res => {
            callback(res.data)
        }).catch(err => callback('error'))
    }
}

export const login = (data, callback) => {
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "login",
            method: 'POST',
            data: data
        }).then(res => {
            callback(res.data)
        }).catch(err => callback('error'))
    }
}


export const getToken = (data, callback) => {
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "getToken",
            method: 'POST',
            data: data
        }).then(res => {
            callback(res.data)
        }).catch(err => callback('error'))
    }
}