import Axios from "axios";
import firebase from 'firebase/app';
import 'firebase/auth';

export const getIdToken = async () => {
    let idToken = localStorage.getItem('idToken');
    return "Bearer " + idToken;
}

export const refreshToken = async () => {
    await firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
            await user.getIdToken().then(function (idToken) {
                localStorage.setItem('idToken', idToken);
            });
        }
    });
}

Axios.interceptors.request.use(config => {
    const token = localStorage.getItem('idToken');
    if (token) {
        config.headers['authorization'] = 'Bearer ' + token;
    }
    return config;
}, error => {
    Promise.reject(error)
});