
let assetPath = {
    player_cards: require('../assets/sound/card_sound_effect.mp3'),
    table_cards: require('../assets/sound/card_sound_effect.mp3'),
    collect_coin: require('../assets/sound/sfx_allin_cashout.mp3')
}

export const PlaySoundFromAssets = function (name) {
    let audioFile = new Audio(assetPath[name]);
    audioFile.pause();
    audioFile.currentTime = 0;
    // audioFile.volume = 0.6;
    audioFile.play();
}
