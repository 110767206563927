import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as gameAction from '../redux/actions/game-action';


function TableList(props) {
    useEffect(() => {

    }, [])

    // const openClubDetails = (name) => {
    //     props.history.push('/club/' + name);

    // }

    // const approveClubMember = () => {

    // }

    const joinClub = (club_name, table_id) => {
        // let userinfo = localStorage.getItem('user')
        let data = {
            table_id: table_id,
            name: props.userInfo.screen_name,
            seatno: null,
            club_name: club_name,
            firebase_id: props.userInfo.firebase_id
        }

        props.gameAction.joinTable(data, (d) => {
            props.history.push(`/ontable?club_name=${club_name}&table_id=${table_id}`, {
                userinfo: props.userInfo
            })
        });
    }

    const resetTable = (club_name, table_id) => {
        let data = {
            table_id: table_id,
            club_name: club_name,
        }
        props.gameAction.resetTable(data, (d) => {
            // props.history.push('/ontable', { club_name: club_name, table_name:  table_id})
        });
    }


    return (
        <div style={{ cursor: "pointer" }} class="game start">
            <div class="title">{props.data.table_name}</div>
            <div class="frame">
                <div class="buyin">
                    <label>Default Game Type</label>
                    <span>{props.data.default_game_type}</span>
                </div>
               
                <div class="players">
                    <label>Created By</label>
                    <span>{props.data.created_by_screen_name}</span>
                </div>
                <div class="action">
                    <button href="javascript:void(0)" style={{ minWidth: '100px' }} onClick={() => resetTable(props.data.club_name, props.data.id)} class="btn btn-info btn-xs btn-block btnPlayNow diff">
                        {`Reset`}
                    </button>
                    <button href="javascript:void(0)" style={{ minWidth: '100px' }} onClick={() => joinClub(props.data.club_name, props.data.id)} class="btn btn-info btn-xs btn-block btnPlayNow diff">
                        {`Join`}
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = dispatch => {
    return {
        gameAction: bindActionCreators(gameAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableList);
