import React, { useState } from 'react';
import * as Animation from '../../config/CardShuffleArrangs';

let hand_data  = [];

function GameTable(props) {

    const {
        table_cards
    } = props;

    // const [hand_data, setHandData] = useState([]);

    if (table_cards && table_cards.length > 0 && hand_data.length !== table_cards.length ) {
        hand_data = table_cards;
        Animation.showTableCards(table_cards)
        // setHandData(table_cards);
    }

    return (
        <>
            <table className="tablecus" style={{ height: '100%', width: '80%' }}>
                <tbody>
                    <tr>
                        <td id="td_1_1"></td>
                        <td id="td_1_2"></td>
                        <td id="td_1_3"></td>
                        <td id="td_1_4"></td>
                        <td id="td_1_5"></td>
                        <td id="td_1_6"></td>
                        <td id="td_1_7"></td>
                        <td id="td_1_8"></td>
                    </tr>
                    <tr>
                        <td id="td_2_1"></td>
                        <td id="td_2_2"></td>
                        <td id="td_2_3"></td>
                        <td id="td_2_4"></td>
                        <td id="td_2_5"></td>
                        <td id="td_2_6"></td>
                        <td id="td_2_7"></td>
                        <td id="td_2_8"></td>
                    </tr>
                    <tr>
                        <td id="td_3_1"></td>
                        <td id="td_3_2"></td>
                        <td id="td_3_3"></td>
                        <td id="td_3_4"></td>
                        <td id="td_3_5"></td>
                        <td id="td_3_6"></td>
                        <td id="td_3_7"></td>
                        <td id="td_3_8"></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

const GameTableMemo = React.memo(GameTable);
export default GameTableMemo;