import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Club from "../screens/Club";
import Login from '../screens/Login';
import OnTable from '../screens/OnTable';
import ClubDetails from '../screens/ClubDetails';
import GameTableHome from '../screens/GameTableHome';
import Main from '../screens/Main';

export default function RouterList() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/club" component={Club} />
                <Route exact path="/club/:name" component={ClubDetails} />
                <Route exact path="/ontable" component={GameTableHome} />
            </Switch>
        </Router>
    )
}