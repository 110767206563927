import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import * as clubAction from '../redux/actions/club-action';
import * as gameAction from '../redux/actions/game-action';


import '../assets/css/club.scss';
import '../assets/css/enginepoker.scss';
import ClubList from './ClubList';


function Club(props) {

    const [svuserid, setUserID] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [clubName, setClubName] = useState("");
    const [inviteCode, setInviteCode] = useState("");
    const [showCreate, setShowCreate] = useState(true);
    const [allClubs, setAllClubs] = useState([]);
    const [joinedClubs, setAllJoinedClubs] = useState([]);
    const [chips, setChipsCount] = useState("");
    const [myChips, setMyChips] = useState(0);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        let userinfo = localStorage.getItem('user')
        if (!userinfo) {
            props.history.push('/');
        } else {
            setUserID(userinfo);
            window.onclick = function (event) {
                if (event.target == document.getElementById("myModal")) {
                    document.getElementById("myModal").style.display = "none";
                }
                if (event.target == document.getElementById("buyModal")) {
                    document.getElementById("buyModal").style.display = "none";
                }
            }
            getClubs(userinfo);
            getUserInfo(userinfo);
        }
    }, [])

    const getClubs = (uid) => {
        let data = {
            firebase_id: uid ? uid : svuserid
        }
        props.clubAction.getAllClubsByUserID(data, (res) => {
            if (res.status == 200) {
                setAllClubs(res.data)
            }
        })
        props.clubAction.getJoinedClubs(data, (res) => {
            if (res.status == 200 && res.data.joined_clubs) {
                setAllJoinedClubs(res.data.joined_clubs)
            }
        })
    }

    const getUserInfo = (uid) => {
        props.clubAction.getUserInfo(uid, (res) => {
            if (res.data) {
                setUserInfo(res.data)
                setMyChips(res.data.chips)
            }
        })
    }

    const resetTextFields = () => {
        setClubName('');
        setInviteCode('');
        setChipsCount('');
    }

    const clubAction = (type) => {
        let data = {
            club_id: clubName,
            invite_code: inviteCode,
        }

        data.userid = userInfo.firebase_id
        data.screen_name = userInfo.screen_name

        if (type === 'create') {
            props.clubAction.createClub(data, (res) => {
                if (res.status === 200) {
                    resetTextFields();
                    document.getElementById("error").style.display = "block";
                    document.getElementById("myModal").style.display = "none";
                    getClubs();
                }
            })
        } else {
            props.clubAction.joinClub(data, (res) => {
                if (res.status === 200) {
                    resetTextFields();
                    document.getElementById("error").style.display = "block";
                    document.getElementById("myModal").style.display = "none";
                } else {
                    document.getElementById("error").style.display = "block";
                }
            })
        }
    }

    const onPressEnterForClub = (e, show) => {
        if (clubName && inviteCode) {
            if (e.key === 'Enter' && show) {
                clubAction('create')
            }
            if (e.key === 'Enter' && !show) {
                clubAction('join')
            }
        }
    }

    const onPressEnterForBuyChips = (e) => {
        if (e.key === 'Enter' && chips) {
            buyChips()
        }
    }

    const buyChips = () => {
        let data = {
            chips: chips,
            firebase_id: userInfo.firebase_id
        }
        props.clubAction.buyChips(data, (res) => {
            resetTextFields();
            document.getElementById("buyModal").style.display = "none";
            getUserInfo(svuserid)
        })
    }

    const showTab = (tabindex) => {
        setActiveTab(tabindex);
    }

    const logOut = () => {
        localStorage.clear();
        // props.history
        props.history.push('/login');
    }

    return (
        <main id="club">
            <div className="page active">
                <div className="fixed-header">
                    <div className="headerName">
                        <span className="logo1">Clubs</span>
                    </div>
                    <div className="chipsIndicator">
                        <div className="icon"></div>
                        <div className="value">{myChips}</div>
                    </div>
                    <div className="top-menu">
                        <a onClick={logOut} href="javascript:void(0)" className="btnItem btnBack icon-back" data-action="page" data-item="#home">
                            <div className="btnItemBackground"></div>
                            <div className="btnItemIcon"></div>
                            <div className="btnItemText">Logout</div>
                        </a>
                        <a href="javascript:void(0)" onClick={() => {
                            document.getElementById("myModal").style.display = "block";
                            setShowCreate(true);
                        }} className="btnItem btnBack icon-clubs" style={{ width: '125px' }} data-action="page" data-item="#home">
                            <div className="btnItemBackground"></div>
                            <div className="btnItemIcon"></div>
                            <div className="btnItemText">Create New Club</div>
                        </a>
                        <a href="javascript:void(0)" onClick={() => {
                            document.getElementById("myModal").style.display = "block";
                            setShowCreate(false);
                        }} className="btnItem btnBack icon-private" data-action="page" data-item="#home">
                            <div className="btnItemBackground"></div>
                            <div className="btnItemIcon"></div>
                            <div className="btnItemText">Join Club</div>
                        </a>
                        <a href="javascript:void(0)" onClick={() => {
                            document.getElementById("buyModal").style.display = "block";
                            setShowCreate(true);
                        }} className="btnItem btnBack icon-casino" data-action="page" data-item="#home">
                            <div className="btnItemBackground"></div>
                            <div className="btnItemIcon"></div>
                            <div className="btnItemText">Buy Chips</div>
                        </a>
                    </div>
                    <div className="tab-menu">
                        <a href="javascript:void(0)"><div className="profile-name">{userInfo && userInfo.screen_name}</div></a>
                        <div className="tab-row row0">
                            <a onClick={() => showTab(0)} href="javascript:void(0)" className={activeTab == 0 ? "btnTab active" : "btnTab"} data-game="texas" data-group="0">MY CLUBS</a>
                            <a onClick={() => showTab(1)} href="javascript:void(0)" className={activeTab == 1 ? "btnTab active" : "btnTab"} data-game="texas" data-group="1">JOINED CLUBS</a>
                        </div>
                    </div>
                </div>
                <div className="fixed-body">
                    <div className="games lobby cashgame">
                        {activeTab === 0 && allClubs && allClubs.map((c, i) => <ClubList key={`cat${i}`} {...props} data={c} />)}
                        {activeTab === 1 && joinedClubs && joinedClubs.map((c, i) => <ClubList key={`ca${i}`} {...props} data={c} />)}
                    </div>
                </div>
            </div>
            <div id="myModal" className="modal">
                <div className="modal-content" id="clubModal">
                    <span onClick={() => {
                        document.getElementById("myModal").style.display = "none";
                    }} className="close">&times;</span>
                    <center><h3>{showCreate ? `CREATE CLUB` : `JOIN CLUB`}</h3></center>
                    <div className="form">
                        <span id="error" class="error" style={{ display: 'none' }}>{"Invalid Club Id or Invite code"}</span>
                        <div className="formControl">
                            <label>{showCreate ? `Club Name` : `Club ID`}</label>
                            <input onKeyDown={(e) => onPressEnterForClub(e, showCreate)} value={clubName} onChange={(e) => setClubName(e.target.value)} type="text" />
                        </div>
                        <div className="formControl">
                            <label>Invite Code</label>
                            <input onKeyDown={(e) => onPressEnterForClub(e, showCreate)} value={inviteCode} onChange={(e) => setInviteCode(e.target.value)} type="text" />
                        </div>
                        <div className="lastPart">
                            {showCreate ? <button onClick={() => clubAction('create')}>Create Club</button> :
                                <button onClick={() => clubAction('join')}>Join Club</button>}
                        </div>
                    </div>
                </div>
            </div>
            <div id="buyModal" className="modal">
                <div className="modal-content" id="clubModal">
                    <span onClick={() => {
                        document.getElementById("buyModal").style.display = "none";
                    }} className="close">&times;</span>
                    <center><h3>{`BUY CHIPS`}</h3></center>
                    <div className="form">
                        <p style={{ color: '#fff' }}>Total Chips: {myChips}</p>
                        <div className="formControl">
                            <label>Enter Chips Count</label>
                            <input onKeyDown={onPressEnterForBuyChips} value={chips} onChange={(e) => setChipsCount(e.target.value)} type="text" />
                        </div>
                        <div className="lastPart">
                            <button onClick={() => buyChips()}>Buy</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = dispatch => {
    return {
        gameAction: bindActionCreators(gameAction, dispatch),
        clubAction: bindActionCreators(clubAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Club);