import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { confirmAlert } from 'react-confirm-alert';
import * as gameAction from '../../redux/actions/game-action';


function GameInfo(props) {
    const {
        club_name,
        table_id,
        hand_id,
        userinfo,
        hand_info
    } = props;

    const [table_info, setTableInfo] = useState(null);

    useEffect(() => {
        props.gameAction.getTableInfo(club_name, table_id, (res) => {
            if (res !== 'error' && res.success) {
                setTableInfo(res.data)
            }
        })
    }, [table_id])

    const submit = () => {
        confirmAlert({
            title: 'Leave Table',
            message: 'Are you sure you want to leave table?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let data = {
                            club_name: club_name,
                            table_id: table_id,
                            hand_id: hand_id,
                            actor: userinfo.screen_name
                        }
                        props.gameAction.leaveTable(data, (d) => {
                            props.history.replace('/club/' + club_name)
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("===NO==="); }
                }
            ]
        });
    }

    let game_info = table_info ? table_info.game_info : {};
    if (hand_info && hand_info.game_name) {
        game_info.name = hand_info.game_name;
        game_info.big_blind = hand_info.big_value;
        game_info.small_blind = hand_info.small_value;
        game_info.betting_rules = hand_info.betting_rules;
    }

    return (
        <div id="GameInfo" className="info">
            <div>
                <i onClick={submit} style={{ fontSize: '7vh', transform: "rotate(180deg)" }} className="fas fa-sign-in-alt"></i>
            </div>
            {game_info.name && <div className="container">
                <div id="lblTableName">{game_info.name}</div>
                <div id="lblSubTitle">{game_info.small_blind}/{game_info.big_blind}{"  "} {game_info.betting_rules}</div>
                {hand_info && <div id="lblGameNo">{hand_info.current_step}</div>}
            </div>}
        </div>
    )
}

const GameInfoMemo = React.memo(GameInfo);
export default GameInfoMemo;