import 'firebase/firestore';
import * as CONSTANT from '../../config/constant';
import axiosRetry from 'axios-retry';
import Axios from "axios";
import { refreshToken } from './action.interceptor';
import * as gameAction from './game-action';

axiosRetry(Axios, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
        return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error) => {
        // if retry condition is not specified, by default idempotent requests are retried
        if (error.response.status === 401) {
            document.getElementById('loadermodal').style.display = 'block';
            refreshToken();
        }
        return error.response.status === 401;
    },
});

export const createClub = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "createClub",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const joinClub = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "joinClub",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const approveClubMember = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "approveClubMember",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const getAllClubsByUserID = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "getAllClubsByUserID",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const getMembersByClubID = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "getMembersByClubID",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const getTablesByClubID = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "getTablesByClubID",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const markSitoutFromNextHand = (data, callback) => {
    // document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "markSitoutFromNextHand",
            method: 'POST',
            data: data
        }).then(res => {
            // document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            // document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const getJoinedClubs = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "getJoinedClubs",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const buyChips = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "buyChips",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const getUserInfo = (uid, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "getUserInfo?firebase_id=" + uid,
            method: 'GET'
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const addChipsToTable = (data, seat_no, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "addChipsToTable",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            if (seat_no >= 0 && seat_no !== null) {
                let data_sit = {
                    seatno: seat_no,
                    table_id: data.table_id,
                    club_name: data.club_name,
                    actor: data.name,
                    action: 'sitin'
                }
                Axios({
                    url: CONSTANT.BASE_PATH + "playerAction",
                    method: 'POST',
                    data: data_sit
                }).then(res => {
                    document.getElementById('loadermodal').style.display = 'none';
                }).catch(err => {
                    document.getElementById('loadermodal').style.display = 'none';
                })
            }
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}