import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { connect } from "react-redux";
import * as loginAction from '../redux/actions/login-action';
import { bindActionCreators } from 'redux';
import store from '../redux/store';
import Club from './Club';
import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap';

import '../assets/css/home.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
import mobile1 from '../assets/home/mobile1.png';
import mobile2 from '../assets/home/mobile2.png';
import mobile3 from '../assets/home/mobile3.png';
import one from '../assets/home/quick-withdrawal.png';
import two from '../assets/home/player-protection.png';
import three from '../assets/home/deposit-limits.png';
import evalpic from '../assets/home/eval_pic.jpeg';
import four from '../assets/home/licensed.png';

function Main(props) {

    useEffect(() => {
        let userinfo = localStorage.getItem('user')
        if (userinfo) {
            props.history.push('/club')
        }
    }, [])

    return (
        <>
        <header id="home_header">
                <header onClick={() => props.history.push('/')}>
                    <h4>Z</h4>
                    <h3>Poker</h3>
                    </header>
                    <div className="anchor">
                    <a href="#">Home</a>
                        <a href="#">Z-Poker</a>
                        <a href="#">How To Play</a>
                        </div>
                
                
                <div id="menu_item">
                    <div id="menu">
                        <a>
                            Admin
                        </a>
                    </div>
                    <div id="menu">
                        <a href="/login" class="btn btn-sm lbtn btn-outline-color" style={{border: '2px solid #ba070c'}}>
                            <span class="fa fa-gamepad"></span>&nbsp;&nbsp;Play Now
                        </a>
                    </div>
                </div>
            </header>
            <div className="start">
                <main id="home1">
                    <div id="home">
                        <h1>Click on <span onClick={() => { props.history.push('/login') }} class="highlight">Play now</span> to enjoy playing poker!</h1>
                        <h5>Zyngl Poker™ provides the best gaming platform.</h5>
                    </div>
                </main>
                {/* <a href="#">Get Start</a> */}
            </div>
            <div className="detail">
                <h2 style={{ textAlign: 'center' }}>Your Online Poker Experience</h2>
               <div className="pictures">
                        <img src={mobile2} style={{ float: 'left' }} alt="mobile2" className="mobile" />
                        {/* <h4>{`Made for You`}</h4>
                        <p>{`Huge Range of Poker Games Affordable Buy-In’s`}</p> */}
                    

                  
                        <img src={evalpic} alt="mobile1" style={{ width: '50%' }} className="mobile1"/>
                    
                        <div>
                            <img style={{ float: 'right' }} src={mobile3} alt="mobile3" className="mobile3" />
                            {/* <h4>{`Made for You`}</h4>
                        <p>{`Huge Range of Poker Games Affordable Buy-In’s`}</p> */}
                        </div>
                        </div>

            </div>
            <Container fluid className="details">
                <h3>
                    Safe & secure
    </h3>
                <p>We pride ourselves on putting our players’ safety and security first.</p>
                <Row >
                <div className="bla">
                    <Col className="row2">
                    <img src={one} alt="mobile1" className="icon" />
                        <h4>Quick Withdrawals</h4>
                        <p>As the world's largest online poker room, we rely on our hard-won.</p>
                    </Col>
                    <Col className="row2">
                    <img src={two} alt="mobile1" className="icon" />
                        <h4>Player Protectio</h4>
                        <p>As the world's largest online poker room, we rely on our hard-won.</p>
                    </Col>
                    </div>
                </Row>
               
                <Row >
                <div className="bla">
                    <Col className="row2">
                        <img src={three} alt="mobile1" className="icon" />
                        <h4>Deposit limits</h4>
                        <p>As the world's largest online poker room, we rely on our hard-won.</p>
                    </Col>
                    <Col className="row2">
                        <img src={four} alt="mobile1" className="icon" />
                        <h4>Game integrity</h4>
                        <p>As the world's largest online poker room, we rely on our hard-won.</p>
                    </Col>
                    </div>
                </Row>


            </Container>

            <div className="footer">
                <Container >
                    <Row>
                        {/* <Col>
                            <p>© Zyngl LLC. All rights reserved.</p>
                        </Col> */}
                        <Col>
                            <p>© Zyngl LLC. All rights reserved.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* <main id="login">
                <div id="home">
                    <h1>Click on <span class="highlight">Play now</span> to enjoy playing poker!</h1>
                    <h5>Zyngl Poker™ provides the best gaming platform.</h5>
                </div>

            </main> */}
        </>
    )
}

export default Main;