import React from 'react';

function ActionButtons(props) {
    const {
        bet,
        raise,
        hand_info,
        userInfo,
        changeBetAmt,
        changeRaiseAmt,
        incrementOrDecrementAmtRaise,
        incrementOrDecrementAmtBet
    } = props;
    return (
        <>
            {hand_info && hand_info.action_metadata && hand_info.action_metadata.action_choices.includes('check') && <a onClick={() => props.onClickActionBtn('check', hand_info.action_metadata.seat_no)} id="btnAutoFold">
                <label class="small"></label>
                <label>check </label>
            </a>}
            {hand_info && hand_info.action_metadata && hand_info.action_metadata.action_choices.includes('fold') && <a onClick={() => props.onClickActionBtn('fold', hand_info.action_metadata.seat_no)} id="btnAutoFold">
                <label class="small"></label>
                <label>fold </label>
            </a>}
            {hand_info && hand_info.action_metadata && hand_info.action_metadata.action_choices.includes('call') && <a onClick={() => props.onClickActionBtn('call', hand_info.action_metadata.seat_no)} id="btnAutoFold">
                <label class="small"></label>
                <label>Call {`${bet}`} </label>
            </a>}
            {hand_info && hand_info.action_metadata && hand_info.action_metadata.action_choices.includes('bet') && <a onClick={() => props.onClickActionBtn('bet', hand_info.action_metadata.seat_no)} id="btnAutoFold">
                <label class="small"></label>
                <label>Bet {`${bet}`} </label>
            </a>}
            {hand_info && hand_info.action_metadata && hand_info.action_metadata.action_choices.includes('raise') && <a onClick={() => props.onClickActionBtn('raise', hand_info.action_metadata.seat_no)} id="btnAutoFold">
                <label class="small"></label>
                <label>Raise To {`${raise}`}</label>
            </a>}
            {hand_info && hand_info.action_metadata && hand_info.action_metadata.action_choices.includes('allin') && <a onClick={() => props.onClickActionBtn('allin', hand_info.action_metadata.seat_no)} id="btnAutoFold">
                <label class="small"></label>
                <label>all-in </label>
            </a>}
            <div id="BetControls">
                {hand_info && userInfo && hand_info.action_metadata && hand_info.action_metadata.action_choices && hand_info.action_metadata.action_to === userInfo.screen_name ?
                    hand_info.action_metadata.action_choices.includes('raise') ?
                        <a href="javascript:void(0)" id="btnBetAmount">
                            <a onClick={() => incrementOrDecrementAmtRaise('decrement')} id="btnBetMinus"></a>
                            <input onChange={(e) => changeRaiseAmt(e.target.value, hand_info.action_metadata.action_to)} type="number" value={raise} className="amount" />
                            <a onClick={() => incrementOrDecrementAmtRaise('increment', hand_info.action_metadata.action_to)} id="btnBetPlus"></a>
                        </a> :
                        hand_info.action_metadata.action_choices.includes('bet') ?
                            <a href="javascript:void(0)" id="btnBetAmount">
                                <a onClick={() => incrementOrDecrementAmtBet('decrement')} id="btnBetMinus"></a>
                                <input onChange={(e) => changeBetAmt(e.target.value, hand_info.action_metadata.action_to)} type="number" value={bet} className="amount" />
                                <a onClick={() => incrementOrDecrementAmtBet('increment', hand_info.action_metadata.action_to)} id="btnBetPlus"></a>
                            </a> :
                            null :
                    null}
            </div>
        </>
    )
}

export default ActionButtons;