import React from 'react';

function MemberList(props) {

    return (
        <div style={{ cursor: "pointer" }} className="game">
            <div className="title"><img src={require("../assets/avatar1.jpg")} className="avatar" />{props.data.screen_name}</div>
            <div className="frame">
                <div className="buyin">
                </div>
                <div className="players">
                </div>
                <div className="players">
                </div>
                <div className="action">
                    {props.data.approved ? <span style={{ color: 'white' }}>{`Approved`}</span> :
                        <a href="javascript:void(0)" onClick={() => props.approveClubMember(props.data.firebase_id)} className="btn btn-info btn-xs btn-block btnPlayNow">
                            {`Approve`}
                        </a>}
                </div>
            </div>
        </div>
    )
}

export default MemberList;
