import firebase from "firebase";
import 'firebase/firestore';
import store from '../store';
import * as CONSTANT from '../../config/constant';
import axiosRetry from 'axios-retry';
import Axios from "axios";
import { refreshToken } from './action.interceptor';

axiosRetry(Axios, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
        return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error) => {
        if (error.response.status === 401) {
            document.getElementById('loadermodal').style.display = 'block';
            refreshToken();
        }
        return error.response.status === 401;
    },
});

let unsubHandInfo = null;
let unsubPlayer = null;
let unsubTableInfo = null;

// export const unsubscribeSnapShot = (callback) => {
//     return async dispatch => {
//         if (typeof (unsubHandInfo) === "function") {
//             unsubHandInfo();
//             store.dispatch({
//                 type: 'SET_HAND_INFO',
//                 payload: null,
//                 hand_id: null
//             })
//         }
//         if (typeof (unsubPlayer) === "function") {
//             unsubPlayer();
//             store.dispatch({
//                 type: 'SET_TABLE_PLAYERS',
//                 payload: null,
//                 table_id: null,
//                 table_name: null
//             })
//         }
//         if (typeof (unsubTableInfo) === "function") {
//             unsubTableInfo();
//         }
//         callback('done');
//     }
// }

export const getAllGameType = (callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "getAllGameType",
            method: 'GET',
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(async (err) => {
            document.getElementById('loadermodal').style.display = 'none';
        })
    }
}

export const getGameTypeByID = (game_type, callback) => {
    // document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "getGameTypeByID?id=" + game_type,
            method: 'GET',
        }).then(res => {
            // document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(async (err) => {
            // document.getElementById('loadermodal').style.display = 'none';
        })
    }
}

export const createTable = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "createTable",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.body)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const cardToArrange = (data, callback) => {
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "cardToArrange",
            method: 'PUT',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.body)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const saveCardShuffle = (data, callback) => {
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "saveCardShuffle",
            method: 'PUT',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.body)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const cardToChange = (data, callback) => {
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "cardToChange",
            method: 'PUT',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.body)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}


export const leaveTable = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "leaveTable",
            method: 'DELETE',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.body)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }
}

export const getTableInfo = (club_anme, table_id, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + `getTableInfo?club_name=${club_anme}&table_id=${table_id}`,
            method: 'GET'
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }

}

export const joinTable = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "joinTable",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            registerForHandSnapshot(data);
            registerPlayerSnapshot(data);
            callback(res.body)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error')
        })
    }

}

export const registerFromUI = (data, callback) => {
    return async dispatch => {
        await registerForHandSnapshot(data);
        await registerPlayerSnapshot(data);
        callback("done")
    }
}

function registerPlayerSnapshot(d) {
    unsubPlayer = firebase.firestore().collection('clubs').doc(d.club_name).collection('tables').doc(d.table_id).collection('players').onSnapshot((res) => {
        let data = res.docs.map((doc) => {
            return doc.data()
        });
        store.dispatch({
            type: 'SET_TABLE_PLAYERS',
            payload: {
                [d.table_id]: data
            },
            table_id: d.table_id,
            table_name: d.table_name
        })
    });
}
let unsubscribe;
function registerForHandSnapshot(d) {
    firebase.firestore().collection('clubs').doc(d.club_name).collection('tables').doc(d.table_id).onSnapshot((res) => {
        if (res.data() && res.data().current_hand) {
            unsubscribe = firebase.firestore().collection('clubs').doc(d.club_name).collection('tables').doc(d.table_id).collection('hands').doc(res.data().current_hand).onSnapshot((hand) => {
                store.dispatch({
                    type: 'SET_HAND_INFO',
                    payload: {
                        [d.table_id]: hand.data()
                    },
                    hand_id: {
                        [d.table_id]: res.data().current_hand
                    },
                    table_info: {
                        [d.table_id]: res.data()
                    }
                })
            });
        } else {
            if (unsubscribe) {
                unsubscribe();
            }
            store.dispatch({
                type: 'SET_HAND_INFO',
                payload: {
                    [d.table_id]: null
                },
                hand_id: null,
                table_info: {
                    [d.table_id]: res.data()
                }
            })
        }
    });
}

export const updateHandWinnerChips = (data, callback) => {
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "updateHandWinnerChips",
            method: 'PUT',
            data: data
        }).then(res => {
            callback(true);
        }).catch(err => {
            callback('error');
        })
    }
}

export const saveCurrentState = (data, callback) => {
    if (data.action === 'sitin') {
        document.getElementById('loadermodal').style.display = 'block';
    }
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "playerAction",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(true);
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
            callback('error');
        })
    }
}

export const setUserPreferences = (data, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        Axios({
            url: CONSTANT.BASE_PATH + "setUserPreferences",
            method: 'POST',
            data: data
        }).then(res => {
            document.getElementById('loadermodal').style.display = 'none';
            callback(res.data)
        }).catch(err => {
            document.getElementById('loadermodal').style.display = 'none';
        })
    }
}

export const resetTable = (d, callback) => {
    document.getElementById('loadermodal').style.display = 'block';
    return async dispatch => {
        firebase.firestore().collection('clubs').doc(d.club_name).collection('tables').doc(d.table_id).update({
            'current_hand': firebase.firestore.FieldValue.delete()
        })
        let pl = await firebase.firestore().collection('clubs').doc(d.club_name).collection('tables').doc(d.table_id).collection('players').get();
        pl.docs.forEach((doc) => {
            doc.ref.delete();
        });
        callback(true);
        document.getElementById('loadermodal').style.display = 'none';
    }
}